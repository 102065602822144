<template>

<div class="relativeDiv" style="margin-left:200px;margin-top:125px" >
  <span id="projectSyncingStatus" v-if="store.state.projectDeleteStatus">
    <h1 class="deleteProjectTitle"><strong>Delete Project</strong></h1>
    <!-- <button class="closeIcon button xButton" @click="store.methods.setStatusModal(false)"></button> -->
    <p class="deleteProjectDesc">
      Are you sure you want to delete your project? This action cannot be<br/>undone and you wont be able to recover any data.
    </p>
    <button class="dashboardButtonHollow button closeButton" @click="store.methods.setStatusModal(false)"
      style="left: 75px; top: 160px;">
      <span class="buttonText" style="color: #009DF8;">Close</span>
    </button>
    <button class="dashboardButton button closeButton" @click="store.methods.deleteProject()"
      style="left: 312px; top: 160px; border: none;">
      <span class="buttonText">Delete</span>
    </button>
  </span>
</div>

</template>

<script setup>
// eslint-disable-

import {
  inject,
} from 'vue';

const store = inject('store');
</script>

<style scoped>

.xButton {
  background-color: transparent;
}

.deleteProjectTitle {
  height: 33px;
  color: #009DF8;
  font-family: 'GT-America-Bold';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  position: absolute;
  top: 32px;
  left: 33px;
}

.deleteProjectDesc {
  height: 43px;
  color: #1C1C1C;
  font-family: 'GT-America-Light';
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  top: 75px;
  left: 33px;
}

.closeButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 280px;
  height: 40px;
  width: 215px;
  left: 193px;
}

.closeIcon {
  background-image: url('../assets/closeicon.svg');
  height: 15px;
  border: 0;
  width: 16px;
  position: absolute;
  top: 34px;
  right: 33px;
}

.titleStatus {
  height: 24px;
  color: #009DF8;
  font-family: 'GT-America-Bold';
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  position: absolute;
  top: 27px;
  left: 33px;
}

.statusText {
  height: 48px;
  color: #009DF8;
  font-family: 'GT-America-Bold';
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  position: absolute;
  left: 33px;
  top: 128px;
}

.percentage {
  color: #009DF8;
}

.otherText {
  height: 24px;
  color: #1C1C1C;
  font-family: 'GT-America-Light';
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  position: absolute;
  left: 33px;
  top: 214px;
}

</style>
