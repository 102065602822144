<template>
	<div>
		<ShareProjectModal v-show="store.state.shareModalVisible" :licenses="store.state.teamLicenseData"
			:world="store.state.shareWorld" :ownerProfile="store.state.worldOwnerProfile"
			:shareInvites="store.state.worldShareUsers" :shareAll="store.state.worldSharedAll">
		</ShareProjectModal>
		<div v-show="store.state.statusModalVisible" style="background-color: rgba(246,246,246,0.6); position: absolute; height: 100%; left: 0; width: 100%; z-index: 4; display: flex;
  align-items: center; justify-content: center;">
		</div>
		<ProfileSettings v-show="store.state.profileSettingsEnabled">
		</ProfileSettings>
		<ProjectStatusModal v-show="store.state.statusModalVisible" :class="{
			statusModal: true, projectSyncStatus: store.state.projectSyncStatus,
			deleteStatus: store.state.projectDeleteStatus
		}">
		</ProjectStatusModal>
		<div v-if="store.state.pageActive === 'home'" style="display: flex; flex-direction: column; position: absolute;margin-top: 125px;
    margin-left: 30px;width:95%">
			<!-- <div class="homeOverlay"></div> -->
			<!-- home screen view -->
			<!--  -->
			<!-- <div class="contentItemContainer" id="homeProjectsContainer" v-show="!store.state.sceneSearchActive">
    <ContentItem v-for="item, i in filteredProjects" :key="i" :item="item" :type="'project'" :viewType="'home'"></ContentItem>
    <div class="relativeDiv" style="display: flex;" v-show="!store.state.allProjectsLoaded">
      <div class="loadingIcons" v-for="icon, i in loadingIconArray" :key="i" :style="loadingIconStyle(i)"></div>
    </div>
    <div class="relativeDiv" v-show="(store.state.allProjects.length === 0 || store.state.allProjectsArchived) && store.state.allProjectsLoaded"
      style="display: flex; align-items: center; justify-content: center;">
      <NoResultsFound :type="'noProjectsHome'"></NoResultsFound>
    </div>
    <div class="relativeDiv" v-show="filteredProjects.length === 0 && store.state.allProjects.length > 0 && !store.state.allProjectsArchived"
      style="display: flex; align-items: center; justify-content: center;">
      <NoResultsFound :type="'searchHome'"></NoResultsFound>
    </div>
  </div> -->
			<!--  -->
			<div class="relativeDiv"
				v-show="(store.state.allProjects.length === 0 || store.state.allProjectsArchived) && store.state.allProjectsLoaded"
				style="display: flex; align-items: center; justify-content: center;">
				<NoResultsFound :type="'noProjectsHome'"></NoResultsFound>
			</div>
			<div class="relativeDiv"
				v-show="filteredProjects.length === 0 && store.state.allProjects.length > 0 && !store.state.allProjectsArchived"
				style="display: flex; align-items: center; justify-content: center;">
				<NoResultsFound :type="'searchHome'"></NoResultsFound>
			</div>
			<div class="loadingContainer" v-show="store.state.allProjects.length != 0 && store.state.allProjectsLoaded">
				<el-row v-if="store.state.allProjects" class="worldsContainer">
					<WorldCard v-for="world in filteredProjects" :key="world.unique_project_id" :world="world" />
				</el-row>
				<!-- <el-empty v-if="worlds && Object.keys(worlds.data).length === 0"
      description="You don't have any projects available yet." /> -->
			</div>


			<!-- <div class="contentItemContainer" id="homeScenesContainer" style="margin-top: 0;" v-show="!store.state.projectSearchActive">
    <ContentItem v-for="item, i in filteredScenes" :key="i" :item="item" :type="'scene'" :viewType="'home'"></ContentItem>
    <div class="relativeDiv" style="display: flex;" v-show="!store.state.allProjectsLoaded">
      <div class="loadingIcons" v-for="icon, i in loadingIconArray" :key="i" :style="loadingIconStyle(i)"></div>
    </div>
    <div class="relativeDiv" style="display: flex;" v-show="!store.state.allProjectsLoaded">
      <div class="loadingIcons" v-for="icon, i in loadingIconArray" :key="i" :style="loadingIconStyle(i)"></div>
    </div>
    <div class="relativeDiv" v-show="(store.state.allProjects.length === 0 || store.state.allProjectsArchived) && store.state.allProjectsLoaded"
      style="display: flex; align-items: center; justify-content: center;">
      <NoResultsFound :type="'noProjectsHome'"></NoResultsFound>
    </div>
    <div class="relativeDiv" v-show="filteredScenes.length === 0 && store.state.allScenes.length > 0 && !store.state.allProjectsArchived"
      style="display: flex; align-items: center; justify-content: center;">
      <NoResultsFound :type="'searchHome'"></NoResultsFound>
    </div>
  </div>
  <div class="contentItemContainer" id="homeScenesContainer" style="margin-top: 0;" v-show="store.state.projectSearchActive">
    <ContentItem v-for="item, i in store.state.allScenes" :key="i" :item="item" :type="'scene'" :viewType="'home'"></ContentItem>
  </div> -->
		</div>
		<!-- end home screen -->
		<div class="contentContainer" id="content" v-if="store.state.pageActive !== 'home'">
			<!-- projects view -->
			<div v-show="pageName == 'Projects' && !store.state.newProjectVisible && !store.state.projectOpen"
				class="relativeDiv">
				<!--loading state -->
				<div class="flexContainer" v-show="!store.state.allProjectsLoaded">
					<div class="loadingIcons" v-for="(icon, i) in loadingIconArray" :key="i">
					</div>
				</div>
				<!-- no results -->
				<div class="relativeDiv"
					v-show="(store.state.allProjects.length === 0 || store.state.allProjectsArchived) && store.state.allProjectsLoaded"
					style="display: flex; align-items: center; justify-content: center;">
					<NoResultsFound :type="'noProjects'"></NoResultsFound>
				</div>
				<div class="relativeDiv"
					v-show="filteredProjects.length === 0 && store.state.allProjects.length > 0 && !store.state.allProjectsArchived"
					style="display: flex; align-items: center; justify-content: center;">
					<NoResultsFound :type="'search'"></NoResultsFound>
				</div>
				<!-- view all projects -->
				<!-- <div class="flexContainer"
      v-show="store.state.allProjectsLoaded && store.state.allProjects.length > 0 && store.state.selectorViewActive === 'view all'">
      <ContentItem v-for="item, i in filteredProjects" :key="i" :item="item" :type="'project'" :viewType="'projects'"></ContentItem>
    </div> -->
				<!-- by me projects -->
				<!-- <div class="flexContainer"
      v-show="store.state.allProjectsLoaded && store.state.allProjects.length > 0 && store.state.selectorViewActive === 'by me'">
      <ContentItem v-for="item, i in filteredProjects" :key="i" :item="item" :type="'project'" :viewType="'projects'"></ContentItem>
    </div> -->
				<!-- view recent projects -->
				<!-- <div class="flexContainer"
      v-show="store.state.allProjectsLoaded && store.state.allProjects.length > 0 && store.state.selectorViewActive === 'recent'">
      <ContentItem v-for="item, i in filteredRecentProjects" :key="i" :item="item" :type="'project'" :viewType="'projects'"></ContentItem>
    </div> -->
				<!-- view archived projects -->
				<!-- <div class="flexContainer"
      v-show="store.state.allProjectsLoaded && store.state.allProjects.length > 0 && store.state.selectorViewActive === 'archived'">
      <ContentItem v-for="item, i in archivedProjects" :key="i" :item="item" :type="'project'" :viewType="'projects'"></ContentItem>
    </div> -->

				<div class="loadingContainer"
					v-show="store.state.allProjects.length != 0 && store.state.allProjectsLoaded">
					<el-row v-if="store.state.allProjects" class="worldsContainer worldsContainerNoPadding">
						<WorldCard v-for="world in filteredProjects" :key="world.unique_project_id" :world="world" />
					</el-row>
					<!-- <el-empty v-if="worlds && Object.keys(worlds.data).length === 0"
      description="You don't have any projects available yet." /> -->
				</div>
			</div>


			<div v-if="store.state.newProjectVisible && !store.state.showHiddenScenes && !store.state.projectOpen"
				class="relativeDiv" style="">
				<NewProject v-bind:widthOfFlexContainer="widthOfFlexContainer"></NewProject>
			</div>
		</div>
	</div>
</template>

<script setup>
/* eslint-disable max-len */

import {
	ref, onMounted, inject, computed, watch, defineProps
} from 'vue';
import NoResultsFound from './NoResultsFound.vue';
import NewProject from './NewProject.vue';
import ProjectStatusModal from './ProjectStatusModal.vue';
import ProfileSettings from './ProfileSettings.vue';
// import ContentItem from './ContentItem.vue';
import WorldCard from './WorldCard.vue';
import ShareProjectModal from './ShareProjectModal.vue';
import { useAuth0 } from '@auth0/auth0-vue';

const { user, getAccessTokenSilently } = useAuth0();

const setWidth = ref(0);
const widthOfFlexContainer = computed(() => `${setWidth.value}px`);

const props = defineProps({
	name: String,
});
const pageName = ref(props.name);

const store = inject('store');

// const getSceneName = (name) => {
//   let processingName;
//   processingName = name.split('\\');
//   processingName = processingName[processingName.length - 1].split('.');
//   const nameToReturn = processingName[0];
//   return nameToReturn;
// };

// const loadingIconStyle = (index) => {
//   if (index !== 0) return { marginLeft: '24px' };
//   return {};
// };

const heightOfWindow = ref(window.innerHeight);
const contentHeight = computed(() => `${heightOfWindow.value - 189}px`);
let windowWidth = window.innerWidth;
const widthOfItemContainer = ref(`${windowWidth - 301}px`);
const getWindowWidth = () => {
	windowWidth = window.innerWidth;
	widthOfItemContainer.value = `${windowWidth - 301}px`;
	store.methods.updateContentWidth(windowWidth - 301);
	// console.log(store.state.contentWidth);
};
window.onresize = getWindowWidth;
onMounted(getWindowWidth);

// computed view variables
//const hiddenScenes = computed(() => store.state.allScenes.filter((scene) => !scene.visible && scene.map_name.toLowerCase().includes(store.state.searchStr.toLowerCase())));
//const hiddenProjectScenes = computed(() => store.state.projectScenes.filter((scene) => !scene.visible && scene.map_name.toLowerCase().includes(store.state.searchStr.toLowerCase())));
// const filteredProjectScenes = computed(() => {
//   if (store.state.projectScenes.length > 0) {
//     return store.state.projectScenes.filter((scene) => scene.visible && scene.map_name.toLowerCase().includes(store.state.searchStr.toLowerCase()));
//   }
//   return [];
// });
// const filteredRecentProjectScenes = computed(() => {
//   const arr = [];
//   let max = 5;
//   if (filteredProjectScenes.value.length < max) max = filteredProjectScenes.value.length;
//   if (filteredProjectScenes.value.length > 0) {
//     for (let i = 0; i < max; i += 1) {
//       arr.push(filteredProjectScenes.value[i]);
//     }
//   }
//   console.log(arr);
//   return arr;
// });
const filteredProjects = computed(() => {
	if (store.state.allProjects.length > 0) {
		return store.state.allProjects.filter((project) => project.visible && project.project_name.toLowerCase().includes(store.state.searchStr.toLowerCase()));
	}
	return [];
});
const filteredRecentProjects = computed(() => {
	const arr = [];
	let max = 5;
	if (filteredProjects.value.length < max) max = filteredProjects.value.length;
	if (filteredProjects.value.length > 0) {
		for (let i = 0; i < max; i += 1) {
			arr.push(filteredProjects.value[i]);
		}
	}
	return arr;
});
watch(filteredRecentProjects, (data) => {
	store.methods.updateRecentProjects(data);
});
// const filteredScenes = computed(() => store.state.allScenes.filter((scene) => scene.visible && scene.map_name.toLowerCase().includes(store.state.searchStr.toLowerCase())));
// const filteredRecentScenes = computed(() => {
//   const arr = [];
//   if (filteredScenes.value.length > 0) {
//     for (let i = 0; i < 5; i += 1) {
//       arr.push(filteredScenes.value[i]);
//     }
//   }
//   return arr;
// });
// const archivedProjects = computed(() => {
//   if (store.state.allProjects.length > 0) {
//     return store.state.allProjects.filter((project) => !project.visible && project.project_name.toLowerCase().includes(store.state.searchStr.toLowerCase()));
//   }
//   return [];
// });

const loadingIconArray = ref([]);

// const computedSearchStr = computed({
//   get() {
//     return store.state.searchStr;
//   },
//   set(str) {
//     store.methods.setSearchStr(str);
//   },
// });
//const minimizedSearchContainer = ref(true);
// const minimizeSearch = () => {
//   minimizedSearchContainer.value = true;
//   store.methods.disableActiveSearch();
// };

const scrollContainerProjects = ref({});
const scrollContainerScenes = ref({});
// const scenesAtMin = ref(true);
// const scenesAtMax = ref(false);
// const backHomeScenesClick = () => {
//   scrollContainerScenes.value.style.scrollBehavior = 'smooth';
//   scrollContainerScenes.value.scrollLeft -= 822;
//   if (scrollContainerScenes.value.scrollLeft <= 0) {
//     scenesAtMin.value = true;
//   } else {
//     scenesAtMin.value = false;
//   }
//   scenesAtMax.value = false;
// };
// const forwardHomeScenesClick = () => {
//   scrollContainerScenes.value.style.scrollBehavior = 'smooth';
//   scrollContainerScenes.value.scrollLeft += 822;
//   if (scrollContainerScenes.value.offsetWidth + scrollContainerScenes.value.scrollLeft >= scrollContainerScenes.value.scrollWidth - 1) {
//     scenesAtMax.value = true;
//   }
//   scenesAtMin.value = false;
// };

let token;
onMounted(async () => {
	for (let i = 0; i < 21; i += 1) {
		loadingIconArray.value.push({});
	}
	const resize = new ResizeObserver(((entries) => {
		entries.forEach((entry) => {
			const cr = entry.contentRect;
			setWidth.value = cr.width;
		});
	}));
	resize.observe(document.querySelector('.headerContainer'));
	window.addEventListener('resize', () => {
		heightOfWindow.value = window.innerHeight;
	});

	scrollContainerProjects.value = document.querySelector('#homeProjectsContainer');
	scrollContainerScenes.value = document.querySelector('#homeScenesContainer');
	if (scrollContainerProjects.value !== null) {
		scrollContainerProjects.value.addEventListener('wheel', (evt) => {
			scrollContainerProjects.value.style.scrollBehavior = 'auto';
			evt.preventDefault();
			scrollContainerProjects.value.scrollLeft += evt.deltaY;
		}, { passive: true });
	}
	if (scrollContainerScenes.value !== null) {
		scrollContainerScenes.value.addEventListener('wheel', (evt) => {
			scrollContainerScenes.value.style.scrollBehavior = 'auto';
			evt.preventDefault();
			scrollContainerScenes.value.scrollLeft += evt.deltaY;
		}, { passive: true });
	}

	token = await getAccessTokenSilently();
	store.methods.apiCallGetUsersForTeam(user, token).then((message) => {
		for (const tuser of message.data.team) {
			if (tuser.unique_user_id == user.value.sub) {
				const primary = tuser.primary_team;
				store.methods.updateIsAdminUser(primary);
			}
		}
		store.methods.setTeamLicenseData(message.data.license);
	});

});

</script>

<style scoped>
.worldsHeader {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;

	.el-pagination.is-background .btn-prev,
	.el-pagination.is-background .btn-next {
		background-color: #fff;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		border: none;

		&:disabled {
			background-color: transparent;
		}
	}

	.el-pagination.is-background .el-pager li {
		background-color: #fff;
		width: 40px;
		height: 40px;
		border-radius: 20px;

		&.is-active {
			background-color: var(--el-color-primary);
		}
	}

	.filters {
		margin-right: 25px;
		display: flex;
		align-items: center;

		input {
			font-family: 'GT-America-Medium';
			color: #1c1c1c
		}

		.el-input__wrapper {
			box-shadow: none !important;
		}

		&__active {
			width: 190px;
		}

		&__alphabet {
			width: 80px;
		}
	}
}

.buttonsGroup {
	display: flex;
	justify-content: flex-end;

	&>button:first-child {
		margin-right: 30px;
	}

	&__pagination {
		.el-button {
			background-color: #fff;
			width: 40px;
			height: 40px;
			border-radius: 10px;
			border: none;

			&.is-disabled {
				background-color: transparent;
			}
		}
	}
}

.worldsContainer {
	padding-top: 40px;
	display: grid;
	gap: 30px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.worldsContainerNoPadding {
	padding-top: 0px !important;
}

.loadingContainer {
	min-height: 500px;
	/* display: flex;
  flex-direction: column; */
}

@media (min-width: 2000px) {
	.worldsContainer {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
}

@media (max-width: 1600px) {
	.worldsContainer {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media (max-width: 1300px) {
	.worldsContainer {
		grid-template-columns: 1fr 1fr;
	}
}

@media (max-width: 1000px) {
	.worldsContainer {
		grid-template-columns: 1fr;
	}
}

.homeOverlay {
	z-index: 10;
	margin-top: 60px;
	position: absolute;
	right: 0;
	height: 100%;
	width: 50px;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 20%, rgba(255, 255, 255, 0.7) 46%, #FFF 87%);
}

.rightArrow {
	transform: rotate(180deg);
}

.viewAllText {
	height: 18px;
	color: #009DF8;
	font-family: 'GT-America-Medium';
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	text-align: center;
	cursor: pointer;
}

.viewAllText:hover {
	text-decoration: underline;
}

.searchIconHeader {
	margin-left: 15.5px;
	height: 20px;
}

.searchContainer {
	position: relative;
	height: 39.55px;
	width: 362px;
	border-radius: 5px;
	background-color: #F6F6F6;
	display: flex;
	align-items: center;
}

.searchContainerActive {
	box-shadow: inset 0 0 0 1px #009DF8;
}

.pageName {
	position: absolute;
	top: 65.5px;
	left: 31px;
	height: 33px;
	color: #009DF8;
	font-family: 'GT-America-Medium';
	font-size: 24px;
	font-weight: bolder;
	line-height: 29px;
}

.contentItemContainer {
	margin-top: 160px;
	margin-left: 31px;
	display: flex;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-x: scroll;
	overflow-y: hidden;
	width: v-bind(widthOfItemContainer);
	height: 370px;
	z-index: 7;
	margin: -32px 0px;
	margin-top: 160px;
	margin-left: 31px;
	padding: 32px 0px;
}

.contentItemContainer::-webkit-scrollbar {
	display: none;
}

.noProjectsYet {
	color: #1C1C1C;
	font-family: 'GT-America-Regular';
	font-size: 14px;
	line-height: 20px;
	text-align: center;
}

.statusModal {
	top: 222px;
	z-index: 4;
	border-radius: 10px;
	background-color: #FFF;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.projectSyncStatus {
	height: 359px;
	width: 600px;
}

.deleteStatus {
	height: 245px;
	width: 600px;
}

.openButton {
	width: 217px;
	left: 17px;
}

.hideButton {
	height: 40px;
	width: 217px;
	top: 395px;
	left: 17px;
}

.itemImg {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-radius: 5px 5px 0 0;
}

.itemImgBackground {
	height: 200px;
	width: 100%;
	position: absolute;
	background: url("../assets/nothumbnaildefault.svg");
	top: 0;
	border-radius: 5px 5px 0 0;
	-webkit-border-radius: 5px 5px 0 0;
	-moz-border-radius: 5px 5px 0 0;
	border: none;
}

.itemLastOpen {
	color: #1C1C1C;
	font-family: "GT-America-Light";
	font-size: 14px;
	line-height: 24px;
}

.itemStatus {
	color: #1C1C1C;
	font-family: "GT-America-Light";
	font-size: 14px;
	line-height: 24px;
}

.itemName {
	height: 37.92px;
	width: 195px;
	color: #009DF8;
	font-family: "GT-America-Medium";
	font-size: 16px;
	line-height: 20px;
	left: 17px;
	top: 216.5px;
	position: absolute;

}

.flexItem {
	height: 460px;
	width: 250px;
	border-radius: 5px;
	background-color: #F6F6F6;
	margin-bottom: 35px;
}

@keyframes flickerAnimation {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.3;
	}

	100% {
		opacity: 1;
	}
}

@-o-keyframes flickerAnimation {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.3;
	}

	100% {
		opacity: 1;
	}
}

@-moz-keyframes flickerAnimation {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.3;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes flickerAnimation {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.3;
	}

	100% {
		opacity: 1;
	}
}

.loadingIcons {
	min-width: 250px;
	height: 310px;
	width: 250px;
	border-radius: 5px;
	background-image: url('../assets/loadingcardicon.svg');
	margin-bottom: 35px;
	-webkit-animation: flickerAnimation 1.5s infinite;
	-moz-animation: flickerAnimation 1.5s infinite;
	-o-animation: flickerAnimation 1.5s infinite;
	animation: flickerAnimation 1.5s infinite;
}

.contentContainer {
	position: absolute;
	bottom: 0;
	height: v-bind(contentHeight);
	width: 95%;
	/* top: 160px; */
	padding-left: 20px;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: v-bind(contentHeight);
	/* padding-top: 32px; */
}

.flexContainer {
	margin: -32px 0px;
	z-index: 7;
	padding: 32px 0px;
	overflow-x: hidden;
	overflow-y: hidden;
	display: grid;
	grid-template-columns: repeat(auto-fill, 250px);
	grid-gap: 37px;
	justify-content: space-between;
	margin-left: -27px;
	padding-right: 35px;
}
</style>
