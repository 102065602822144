<template>
  <div style="overflow: visible;">
    <div ref="bannerRef" class="worldUpload" >
      <el-upload ref="uploadRef" drag action="#" :show-file-list="false" :auto-upload="false" :on-change="uploadSuccess" :limit="1"
        :on-exceed="handleExceed" :accept="`.${props.fileType}`">
        <div class="el-upload__text d-flex align-center justify-center direction-column" style="margin-top:5px">
          <img src="@/assets/uploadicon.svg" alt="Uploadicon" />
          <div class="el-upload__text" style="color:#009DF8">Choose a file or drag it here to upload.</div>
        </div>
      </el-upload>
      <div class="uploadingOverlay" v-show="props.uploading" >
        <div class="smallCircleLoader" ></div>
        <div style="font-family:GT-America-Medium;color:#009DF8;margin-top:10px">{{ props.uploadstatus }}</div>
      </div>
      <div v-if="file || props.file" >
        <div class="uploadedFile d-flex justify-between align-center">
          <div>
            <h4 class="fileName">{{ file?.name || props.file.name}}</h4>
            <span class="fileSize">{{ fileSize || props.file.size}}</span>
          </div>
          <el-button class="fileRemove" type="danger" link @click="removeFile">Remove</el-button>
        </div>
        <div class="dashboardButton settingsButton" style="margin-top:10px" @click="uploadWorld">
          <div class="buttonText" @click="uploadWorld">Upload Project .Zip</div>
          </div>
      </div>
      
    </div>
   
    <div class="d-flex justify-between mt-10">
    </div>
  </div>
  </template>
  
  <script setup>
  import { ref, computed,defineEmits,defineProps } from 'vue'
  import { genFileId, ElMessage } from 'element-plus'
  import useUtils from './utils.js'
  
  const { humanFileSize } = useUtils()
  
  const emits = defineEmits(['fileChanged', 'fileRemoved', 'uploadFile'])
  const props = defineProps({
    width: {
      type: String,
      required: true
    },
    file: {
      type: Object
    },
    fileType: {
      type: String,
      required: true
    },
    uploading: {
      type: Boolean,
      default: false,
      required: true
    },
    uploadstatus: {
      type: String,
      default: 'Uploading...'
    },
  })
  
  const uploadRef = ref(null)
  const file = ref(null)
  const bannerRef = ref(null)
  
  
  const uploadWorld = (e) => {
    e.stopPropagation();
    if (file.value) {
      emits('uploadFile', file.value)
    } else {
      uploadRef.value.submit()
    }
  }
  const fileSize = computed(() => file.value ? humanFileSize(file.value.size) : null)
  
  const uploadSuccess = async (res) => {
    if (res.raw.size / 1048576 > 20480) {
      ElMessage.error('The file must not exceed 20 GB')
    } else {
      file.value = res.raw
      const newFile = res
      newFile.raw = new File([newFile.raw], newFile.raw.name.replace(/\s/g, ''))
    }
  }
  
  const removeFile = async () => {
    emits('fileRemoved')
    file.value = null
  }
  const handleExceed = (files) => {
    uploadRef.value.clearFiles()
    const file = files[0]
    file.uid = genFileId()
    uploadRef.value.handleStart(file)
  }
  </script>
  
  <style lang="scss">
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .uploadingOverlay{
    width: 100%;
      height: 115px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      padding: 0px 40px;
      box-sizing: border-box;
      justify-content: center;
    z-index: 10;
  }

  .settingsButton {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
  }

  .smallCircleLoader {
  height: 41px;
  width: 41px;
  background-image: url('../assets/smallloadingicon.svg');
  -webkit-animation: spin 0.75s linear infinite;
  -moz-animation: spin 0.75s linear infinite;
  animation: spin 0.75s linear infinite;
}

  .worldUpload {
    position: relative;
    color: #c8c8c8;
    // border-radius: 5px;
    max-width:950px;
    display: flex;
    flex-direction: column;
    overflow: visible;
  
    .uploadedFile {
      width: 100%;
      height: 106px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      display: flex;
      align-items: center;
      padding: 0px 40px;
      box-sizing: border-box;
      color: #000;
      border-radius: 5px;
      justify-content: space-between;
  
      .fileName {
        margin: 0;
        font-size: 14px;
        margin-bottom: 5px;
        overflow: hidden;
        font-family: 'GT-America-Medium';
      }

      .fileRemove
      {
        font-size: 14px;
        text-decoration: underline;
        font-style: italic;
        color: #f00;
        font-family: 'GT-America-Medium';
      }

      .fileSize {
        margin: 0;
        font-size: 14px;
        margin-bottom: 5px;
        overflow: hidden;
        font-family: 'GT-America-Regular';
      }
  
      .el-button {
        font-size: 14px;
        text-decoration: underline;
        font-style: italic;
        color: #f00;
        font-family: 'GT-America-Medium';
      }
    }
  
    .el-upload-dragger {
      padding: 0;
      background-color: transparent;
      color: var(--el-color-primary);
      border: 1px dashed #009DF8;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
  
      &:hover {
        background-color: rgba(85, 155, 245,0.05);
      }
  
      .el-icon--upload {
        font-size: 23px;
        margin: 0;
      }
    }
  
    .el-upload__text {
      div {
        font-size: 12px;
        padding: 5px;
        color: var(--el-color-primary);
        font-family: 'GT-America-Medium';
      }
  
      &>* {
        height: fit-content;
      }
    }
  
    // &>div,
    // .el-upload-dragger {
    //   height: v-bind(height);
    // }
  }
  .world {
  max-width: 1000px;
  color: #1c1c1c;
  font-size: 12px;
  // padding-bottom: 15px;
  margin: 0 auto;
  position: relative;
  min-height: calc(100vh - 120px);

  .title {
    font-size: 30px;
    font-family: 'GT-America-Medium';
    margin: 0;
  }


  .el-input__wrapper {
    background-color: #fff;
    padding: 0;
    padding: 5px 15px;
    margin-top: 5px;
  }

  .el-textarea__inner {
    box-shadow: none;
  }

  label {
    font-size: 12px;
    font-family: 'GT-America-Medium';
    margin-top: 24px;
    display: block;
  }

  small {
    font-size: 12px;
    margin-bottom: 10px;
    display: block;
  }

  h3 {
    margin: 0;
    font-size: 16px;
    font-family: 'GT-America-Bold';
  }

  .el-dialog__body {
    img {
      width: 100%;
    }
  }

  .el-select {
    display: block;
  }

  .publish,
  .thumbnail {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 10px;
    overflow: hidden;

    p {
      font-size: 12px;
      margin: 0;
      line-height: 24px;
      margin-bottom: 5px;
    }
  }

  .el-button {
    font-family: 'GT-America-Medium';

    &.is-link {
      height: 40px;
      font-style: italic;
      text-decoration: underline;
    }
  }

  .thumbnail {

    .el-upload-list__item,
    .el-upload--picture-card {
      width: 275px;
      height: 275px;
      margin: 0 auto;
      display:none;
    }
  }

  .gallery {
    .el-upload-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-flow: dense;
      display:none;
    }

    .el-upload-list__item,
    .el-upload--picture-card {
      width: 185px;
      height: 115px;
      display:none;
    }

    .el-upload-list--picture-card {
      gap: 23px;
      display:none;
    }
  }

  .sticky-container {
    position: sticky;
    top: 30px;
  }
}
  </style>
  