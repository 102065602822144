<template>
  <div class="childrenFill" style="display: flex;">
    <router-view></router-view>
  </div>
</template>

<script>
import { provide } from 'vue';
import store from '@/store';
import { useAuth0 } from '@auth0/auth0-vue';


export default {
  name: 'App',
  components: {
  },
  mounted()
  {

  },  
  setup() {

      const { loginWithRedirect, user, isAuthenticated, getAccessTokenSilently} = useAuth0();

      store.methods.updateAccessToken(getAccessTokenSilently());
      provide('user', user);
      provide('store', store);

      return {
        login: () => {
          loginWithRedirect();
        },
        store,
        user,
        isAuthenticated
      };
  },
}
</script>

<style>
.topBarBorder {
  height: 0;
  width: 100%;
  border-bottom: 1px solid #DDD;
}

::-webkit-scrollbar {
  background: #FFF;
  width: 10px;
  padding-top: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #E8E8E8;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #adadad;
}

::-webkit-scrollbar-thumb:active {
  background-color: #adadad;
}

.relativeDiv {
  position: relative;
  width: 100%;
  height: 100%;
}

.underlineTextActive {
  color: #009DF8;
  font-family: "GT-America-Regular";
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  cursor: pointer;
}

.underlineText {
  color: #009DF8;
  font-family: "GT-America-Medium";
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}

.underlineText:hover {
  cursor: pointer;
}

.viewLabel:hover {
  text-decoration: underline;
}

::selection {
   background: rgb(171, 208, 238);
}

.buttonText {
  position: absolute;
  color: #FFF;
  font-family: 'GT-America-Medium';
  font-size: 14px;
  font-weight: lighter;
  line-height: 17px;
  height: 14.95px;
  text-align: center;
}

.buttonTextHover {
  opacity: 1.5;
}

.buttonText::selection {
   background: transparent;
}

.dashboardButton {
   height: 40px;
   border-radius: 10px;
   background-color: #009DF8;
}
.dashboardButton:hover {
   opacity: 0.9;
   cursor: pointer;
}

.dashboardButton:hover:active {
   /* border: 0.5px solid #FFF; */
   opacity: 1.1;
}

.contentButton {
  display: flex;
  align-items: center;
  justify-content: center;
}



.searchInput {
   left: 60px;
   margin-left:10px;
   height: 100%;
   width: 302px;
   color: #1C1C1C;
   font-family: 'GT-America-Regular';
   font-size: 14px;
   font-weight: 300;
   line-height: 17px;
   text-align: left;
   opacity: 0.8;
   outline: none;
   border: none;
   background: transparent;
}

.searchInput::selection {
   background: #009DF8
}

.dashboardButtonHollow {
  height: 40px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #009DF8;
}

.button {
  outline: none;
  padding: 0;
}

.button:focus {
  outline: 0 !important;
}

.searchIcon {
   position: absolute;
   left: 27px;
   top: 10.79px;
   height: 17.98px;
   width: 17.8px;
   background-image: url("./assets/searchicon.svg");
}

.dashboardButtonHollow:hover {
  cursor: pointer;
  background: #faf9fc;
}

img {
   -webkit-user-drag: none;
   -khtml-user-drag: none;
   -moz-user-drag: none;
   -o-user-drag: none; -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.dropdownArrow {
   position: absolute;
   top: 15.52px;
   height: 11px;
   right: 2px;
   transform: rotate(90deg);
}

.arrow {
   width: 4.96px;
   height: 10px;
   background-image: url("./assets/arrow.svg");
}

.dropdownTextContainer {
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  height: 32px;
  margin-left: 14px;
}

.dropdownEmail {
   flex: 0 0 15px;
   color: #1C1C1C;
   font-family: "GT-America-Light";
   font-size: 12px;
   line-height: 15px;
   text-align: left;
   left: 100px;
   top: 45px;
   margin-left: 2px;
}

.profileImage {
   height: 40px;
   width: 40px;
   border-radius: 33px;
   object-fit: cover;
   min-width: 40px;
}

.dropdownHeader {
   flex: 0 0 17px;
   color: #1C1C1C;
   font-family: "GT-America-Medium";
   font-size: 14px;
   line-height: 17px;
   text-align: left;
}

.userDropdown {
   position: absolute;
   top: 60px;
   right: 48px;
   height: 80px;
   width: 278px;
   border-radius: 0 0 10px 10px;
   background-color: #FFF;
   box-shadow: 0 1px 4px 0 rgba(0,0,0,0.2);
   z-index: 2;
}

@font-face
{
	font-family: 'GT-America-Black'; /*a name to be used later*/
	src: url('assets/fonts/GT-America-Standard-Black.ttf') format('truetype'); /*URL to font*/
}

@font-face
{
	font-family: 'GT-America-Regular'; /*a name to be used later*/
	src: url('assets/fonts/GT-America-Standard-Regular.ttf') format('truetype'); /*URL to font*/
}

@font-face
{
	font-family: 'GT-America-Bold'; /*a name to be used later*/
	src: url('assets/fonts/GT-America-Standard-Bold.ttf') format('truetype'); /*URL to font*/
}

@font-face
{
	font-family: 'GT-America-Medium'; /*a name to be used later*/
	src: url('assets/fonts/GT-America-Standard-Medium.ttf') format('truetype'); /*URL to font*/
}

@font-face
{
	font-family: 'GT-America-Thin'; /*a name to be used later*/
	src: url('assets/fonts/GT-America-Standard-Thin.ttf') format('truetype'); /*URL to font*/
}

@font-face
{
	font-family: 'GT-America-Light'; /*a name to be used later*/
	src: url('assets/fonts/GT-America-Standard-Light.ttf') format('truetype'); /*URL to font*/
}

.main {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
}

.childrenFill{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    flex-direction: row;
    display: flex;
}
</style>
