<template>

	<div>
		<!-- eslint-disable max-len -->
		<div v-show="store.state.ddcModalVisible || store.state.profileSettingsEnabled"
			style="background-color: rgba(246,246,246,0.6); position: absolute; height: 100%; left: 0; width: 100%; z-index: 8;">
		</div>
		<div class="headerContainer" :style="headerStyle">
			<div class="relativeDiv" :style="headerStyle">
				<div class="relativeDiv topBarBorder" style="height: 60px;">
					<div style="flex: 0 0 60px; height: 60px; position: relative;" class="topBarBorder"
						v-show="store.state.pageActive === 'manageteam'">
						<div
							style="position: absolute; left: 80px; height: 18px; width: 170px; top: 21px; display: flex;">
						</div>
					</div>
					<div v-if="user" @click="toggleDropdown"
						style="position: absolute; right: 50px; top: 10px; width: 276px; height: 40px; cursor: default; display: flex;"
						href="#" id="profileDropDown">
						<img :src="user.picture" alt="User's profile picture" class="profileImage"
							v-show="!store.state.newProfileImage" />
						<img :src="store.state.newProfileImageURL" style="object-fit: cover;"
							alt="User's profile picture" class="profileImage" v-show="store.state.newProfileImage">
						<div class="dropdownTextContainer" @click.stop="toggleDropdown">
							<div v-if="user" class="dropdownHeader">{{ user.name }}</div>
							<div v-if="user" class="dropdownEmail">{{ user.email }}</div>
						</div>
						<div class="dropdownArrow arrow"></div>
					</div>
					<div class="userDropdown" v-show="dropdownVisible">
						<div class="relativeDiv"
							style="display: flex; justify-content: center; flex-direction: column;">
							<div class="dropdownOption" @click.prevent="settingsSelected">
								<span class="optionText" style="margin-top: 1px;">Account Settings</span>
							</div>
							<div @click.prevent="logoutFromUI" class="dropdownOption">
								<span class="optionText" style="color: #FF0004;">Sign Out</span>
							</div>
						</div>
					</div>
				</div>
				<div style="display:flex;margin-top:30px;margin-left:27px;margin-right:60px;flex-direction:row;justify-content: space-between;" 
					v-if="!store.state.projectOpen && !store.state.manageTeamActive">
					<div v-if="!store.state.projectOpen && !store.state.editingProject && !store.state.manageTeamActive"
						class="pageName"><span class="pageNameText">{{ headerName }}</span></div>
					<div v-if="!store.state.projectOpen && store.state.editingProject && !store.state.manageTeamActive"
						class="pageName"><span class="pageNameText">{{ store.state.editingProjectName }}</span>
						<div v-if="!store.state.projectOpen && store.state.editingProject && !store.state.manageTeamActive"
							class="backToProject" @click="store.methods.updatePage('home')">
							<img src="../assets/back-arrow.svg" alt="back arrow icon" style="margin-top: -1px;">
							&nbsp;Back to Projects
						</div>
					</div>
					<div v-if="store.state.projectOpen && !store.state.manageTeamActive" class="pageName"><span
							class="pageNameText">{{ store.state.projectOpenName }}</span></div>
					<div v-show="headerName !== 'New Project' && headerName !== 'editing project' && !store.state.manageTeamActive && store.state.isUserAdmin"
						>
						<div style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
							<div class="searchContainer"
								style="width: 40px; cursor: pointer; justify-content: center;"
								v-show="minimizedSearchContainer" @click="minimizedSearchContainer = false">
								<img src="../assets/searchicon.svg" alt="search icon" style="height: 20px;">
							</div>
							<div :class="{ searchContainer: true, searchContainerActive: store.state.projectSearchActive }"
								v-if="!minimizedSearchContainer">
								<img src="../assets/searchicon.svg" alt="search icon" class="searchIconHeader">
								<input class="searchInput" spellcheck="false" style="left: 45px; width: 318px;"
									v-model="computedSearchStr" :placeholder="defaultSearchValue"
									@focus="store.methods.toggleActiveSearch('projects')" id="projectSearch" />
							</div>
							<div class="dashboardButton" style="width:140px"
								@click="store.methods.toggleNewProjectVisibility()"
								v-show="store.state.pageActive !== 'scenes'">
								<div class="plusIcon"></div>
								<div class="buttonText" style="position:relative;top:-3.25px;left: 10px;">New Project</div>
							</div>
						</div>
					</div>
					<div style="height: 40px; width: 316px; position: absolute; right: 47px; top: 37px;"
						v-show="store.state.pageActive === 'projects'">
						<div class="relativeDiv" style="display: flex; align-items: center;">
							<div class="tabSelectorContainer">
								<div :class="{ selectorText: true, selectorActive: store.state.selectorViewActive === 'recent' }"
									@click="store.methods.updateSelectorView('recent')">Recent</div>
								<div :class="{ selectorText: true, selectorActive: store.state.selectorViewActive === 'view all' }"
									style=" margin-left: 21px;" @click="store.methods.updateSelectorView('view all')">
									View All
								</div>
								<div :class="{ selectorText: true, selectorActive: store.state.selectorViewActive === 'by me' }"
									style=" margin-left: 21px;" @click="store.methods.updateSelectorView('by me')">
									By Me
								</div>
								<div :class="{ selectorText: true, selectorActive: store.state.selectorViewActive === 'archived' }"
									style=" margin-left: 21px;" @click="store.methods.updateSelectorView('archived')">
									Archived
								</div>
							</div>
						</div>
					</div>
					<div style="height: 40px; width: 296px; position: absolute; right: 47px; top: 37px;"
						v-show="store.state.pageActive === 'scenes'">
						<div class="relativeDiv" style="display: flex; align-items: center;">
							<div class="tabSelectorContainer">
								<div :class="{ selectorText: true, selectorActive: store.state.selectorViewActive === 'recent' }"
									@click="store.methods.updateSelectorView('recent')">Recent</div>
								<div :class="{ selectorText: true, selectorActive: store.state.selectorViewActive === 'view all' }"
									style=" margin-left: 21px;" @click="store.methods.updateSelectorView('view all')">
									View All
								</div>
								<div :class="{ selectorText: true, selectorActive: store.state.selectorViewActive === 'hidden scenes' }"
									style=" margin-left: 21px;"
									@click="store.methods.updateSelectorView('hidden scenes')">
									Hidden Scenes
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {
	ref, inject, computed, onMounted,
} from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
// import {
//   useRoute
// } from 'vue-router';

export default {
	name: 'PageHeader',
	setup() {
		const { logout } = useAuth0();

		const store = inject('store');
		//const route = useRoute();

		const headerStyle = computed(() => {
			if (store.state.pageActive === 'manageteam') return { height: '60px' };
			return {};
		});

		// const login = () => {
		//     loginWithRedirect();
		//     route.push({ path: '/' });
		// };
		const logoutFromUI = () => {
			logout();
			//route.push({ path: '/' });
		};

		const headerStyleTwo = computed(() => {
			if ((store.state.pageActive === 'new project' || store.state.pageActive === 'editing project')) return { height: '100px' };
			return { height: '129px' };
		});
		// const Auth = inject('Auth');
		const { user } = useAuth0();

		// const headerButtonStyle = computed(() => {
		// 	if (store.state.pageActive === 'home') {
		// 		return {
		// 			position: 'absolute', top: '37px', right: '245px', height: '40px',
		// 		};
		// 	}
		// 	if (store.state.pageActive === 'projects') {
		// 		return {
		// 			position: 'absolute', top: '37px', right: '400px', height: '40px',
		// 		};
		// 	}
		// 	return {
		// 		position: 'absolute', top: '37px', right: '350px', height: '40px',
		// 	};
		// });

		const headerName = computed(() => {
			if (store.state.pageActive === 'home') return 'Projects';
			if (store.state.pageActive === 'projects') return 'Projects';
			if (store.state.pageActive === 'scenes') return 'Scenes';
			if (store.state.pageActive === 'new project') return 'New Project';
			if (store.state.pageActive === 'editing project') return 'editing project';
			return 'Projects';
		});
		const toggleViews = (type) => {
			if (type === 'hidden scenes') {
				store.methods.updateMainView(false);
				store.methods.toggleHiddenScenes(true);
			}
			if (type === 'archived projects') {
				store.methods.updateMainView(false);
				store.methods.toggleArchivedProjects(true);
			}
			if (type === 'main view') {
				store.methods.updateMainView(true);
				store.methods.toggleHiddenScenes(false);
				store.methods.toggleArchivedProjects(false);
			}
		};

		const dropdownVisible = ref(false);
		const toggleDropdown = () => {
			dropdownVisible.value = !dropdownVisible.value;
			store.methods.updateHeaderDropdownVisible(dropdownVisible.value);
		};
		const hideDropdown = (e) => {
			// console.log('event from v click outside: ', e);
			if (e.srcElement.id !== 'profileDropDown'
				&& e.srcElement.className !== 'profileImage'
				&& e.srcElement.className !== 'dropdownArrow arrow') {
				dropdownVisible.value = false;
				store.methods.updateHeaderDropdownVisible(dropdownVisible.value);
			}
		};

		const computedSearchStr = computed({
			get() {
				return store.state.searchStr;
			},
			set(str) {
				store.methods.setSearchStr(str);
			},
		});
		const minimizedSearchContainer = ref(false);
		const minimizeSearch = () => {
			minimizedSearchContainer.value = false;
			store.methods.disableActiveSearch();
		};
		const defaultSearchValue = computed(() => `Search ${headerName.value}`);

		const settingsSelected = () => {
			dropdownVisible.value = false;
			store.methods.updateHeaderDropdownVisible(dropdownVisible.value);
			store.methods.enableProfileSettings(true);
		};

		const projectsAtMin = ref(true);
		const projectsAtMax = ref(false);
		const backHomeProjectsClick = () => {
			const projectsContainer = document.querySelector('#homeProjectsContainer');
			projectsContainer.style.scrollBehavior = 'smooth';
			projectsContainer.scrollLeft -= 822;
			if (projectsContainer.scrollLeft <= 0) {
				projectsAtMin.value = true;
			} else {
				projectsAtMin.value = false;
			}
			projectsAtMax.value = false;
		};
		const forwardHomeProjectsClick = () => {
			const projectsContainer = document.querySelector('#homeProjectsContainer');
			projectsContainer.style.scrollBehavior = 'smooth';
			projectsContainer.scrollLeft += 822;
			if (projectsContainer.offsetWidth + projectsContainer.scrollLeft >= projectsContainer.scrollWidth - 1) {
				projectsAtMax.value = true;
			}
			projectsAtMin.value = false;
		};

		//const setWidth = ref(0);
		onMounted(async () => {


			// store.state.manageTeamActive = false;

			// const resize = new ResizeObserver(((entries) => {
			//   entries.forEach((entry) => {
			//     const cr = entry.contentRect;
			//     // console.log('width of header container: ', cr.width);
			//     setWidth.value = cr.width;
			//   });
			// }));
			// // start observing for resize
			// resize.observe(document.querySelector('.headerContainer'));
		});

		return {
			headerName,
			dropdownVisible,
			toggleDropdown,
			hideDropdown,
			defaultSearchValue,
			store,
			toggleViews,
			computedSearchStr,
			settingsSelected,
			user,
			headerStyle,
			headerStyleTwo,
			minimizedSearchContainer,
			backHomeProjectsClick,
			forwardHomeProjectsClick,
			projectsAtMin,
			projectsAtMax,
			minimizeSearch,
			logoutFromUI
		};
	},
};

</script>

<style scoped>
.selectorText {
	height: 34.5px;
	color: #009DF8;
	font-family: 'GT-America-Medium';
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	cursor: pointer;
}

.selectorActive {
	border-bottom: 2px solid #009DF8;
}

.tabSelectorContainer {
	height: 34.5px;
	width: 100%;
	border-bottom: 1px solid rgba(85, 155, 245, 0.4);
	display: flex;
}

.backToProject {
	margin-left: 19px;
	height: 17px;
	color: #009DF8;
	font-family: 'GT-America-Regular';
	font-size: 14px;
	line-height: 17px;
	cursor: pointer;
}

.rightArrow {
	transform: rotate(180deg);
}

.viewAllText {
	height: 18px;
	color: #009DF8;
	font-family: 'GT-America-Medium';
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	text-align: center;
	cursor: pointer;
}

.viewAllText:hover {
	text-decoration: underline;
}

.searchIconHeader {
	margin-left: 15.5px;
	height: 20px;
}

.headerOption:hover {
	text-decoration: underline;
}

.headerOption {
	height: 100%;
	color: #009DF8;
	font-family: 'GT-America-Regular';
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	cursor: pointer;
}

.optionText {
	height: 17px;
	color: #1C1C1C;
	font-family: 'GT-America-Medium';
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	margin-left: 22px;
	cursor: pointer;
}

.dropdownOption {
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.dropdownOption:hover {
	background: #F6F6F6;
}

.settingsLabel {
	height: 18px;
	color: #009DF8;
	font-family: "GT-America-Medium";
	font-size: 16px;
	line-height: 20px;
}

.plusIcon {
	position: relative;
	top: 12.5px;
	left: 13px;
	background-image: url("../assets/addicon.svg");
	height: 14.42px;
	width: 14.42px;
}

.searchContainer {
	position: relative;
	margin-right: 30px;
	margin-left:25px;
	height: 39.55px;
	width: 362px;
	border-radius: 5px;
	background-color: #F6F6F6;
	display: flex;
	align-items: center;
}

/* .searchContainerActive {
	box-shadow: inset 0 0 0 1px #009DF8;
} */

.pageName {
	/* position: absolute;
	top: 45px;
	left: 31px;
	height: 29px; */
	/* margin-top:25px; */
	display: flex;
	align-items: center;
}

.pageNameText {
	color: #009DF8;
	font-family: 'GT-America-Medium';
	font-size: 24px;
	/* font-weight: bold; */
	line-height: 29px;
}

.headerContainer {
	/* height: 115px; */
	width: 100%;
	background: transparent;
	position: absolute;
	top: 0;
	z-index: 10;
}
</style>
