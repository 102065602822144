<template>

	<div style="display:flex">
		<div v-show="store.state.statusModalVisible || store.state.ddcModalVisible || store.state.profileSettingsEnabled"
			style="background-color: rgba(246,246,246,0.6); position: absolute; height: 100%; left: 0; width: 100%; z-index: 3;">
		</div>
		<div style="flex: 0 0 270px; z-index: 2; display: flex; flex-direction: column; overflow-y: hidden;">
			<div style="flex: 0 0 60px; height: 60px; position: relative;" class="topBarBorder">
				<!-- <img src="../assets/sequinlogo.svg" alt="sequin logo" style="position: absolute; left: 18px; top: 10px;"> -->
				<div style="position: absolute; left: 20px; height: 18px; width: 170px; top: 21px; display: flex;">
					<!-- <div class="headerOption" style="flex: 0 0 93px;">Help Center</div> -->
					<!-- <div class="headerOption" style="flex: 0 0 64px; margin-left: 13px;">Upgrade</div> -->
				</div>
			</div>
			<nav class="navBar" style="flex: 0 0 100%; position: relative; min-height: 436px;">
				<div class="navContainer">
					<div style="postion: relative; display: flex; align-items: center; flex-direction: column;">
						<div style="height: 126px; display: flex; align-items: center; width: 100%; padding-top: 29px; position: relative; display: none;
          justify-content: center;">
							<div class="teamDropdown" @click="toggleTeamSelector" :style="dropdownSelectorStyle">
								<div class="selectorTextContainer">
									<span class="selectorTitle">Team Name</span>
									<span class="selectorTitle"
										style="font-family: 'GT-America-Light'; font-weight: 200;">My Projects</span>
								</div>
								<div class="arrowSelector">
									<img src="../assets/arrow.svg" alt="selector icon"
										style="transform: rotate(-90deg) scale(0.6);" class="selectorArrow">
									<div style="width: 4px; height: 22px;"></div>
									<img src="../assets/arrow.svg" alt="selector icon"
										style="transform: rotate(90deg) scale(0.6);" class="selectorArrow">
								</div>
							</div>
						</div>
						<div class="teamDropdownContainer" v-show="teamSelectorOpen">
							<div class="dropdownScroll">
								<div class="teamDropdownItem" v-for="(item, i) in dropdownItems" :key="i">
									<div class="selectorTextContainer" style="width: 168px; left: 52px;">
										<span class="selectorTitle">Team Name</span>
										<span class="selectorTitle"
											style="font-family: 'GT-America-Light'; font-weight: 200;">Projects
											Type</span>
									</div>
								</div>
							</div>
							<div class="dropdownContainerBottom"></div>
						</div>
						<div style="position: relative; top: 27px; display: flex; flex-direction: column;">
							<router-link to="/home" class="routerLink" id="routerLink1" draggable="false"
								@click="store.methods.updatePage('home')" v-show="!store.state.projectFilesUploading">
								<div
									:class="{ navItem: store.state.pageActive !== 'home', navItemActive: store.state.pageActive === 'home' }">
									<div class="navIcon">
										<img src="../assets/homeicon.svg" alt="home icon">
									</div>
									<div class="underlineText navItemText">Home</div>
								</div>
							</router-link>
							<div class="navSpacer"></div>
							<!-- <div class="routerLink" id="routerLink2" @mouseover="projectsHovered = true"
								@mouseleave="projectsHovered = false" @click="clickProjects"
								v-show="!store.state.projectFilesUploading" draggable="false" style="cursor: pointer;">
								<div
									:class="{ navItem: store.state.pageActive !== 'projects', navItemActive: store.state.pageActive === 'projects' }">
									<div class="navIcon">
										<img src="../assets/projectsicon.svg" alt="projects icon">
									</div>
									<div class="underlineText navItemText">Projects</div>
									<div class="arrowContainer"
										v-show="(projectsHovered || (store.state.pageActive === 'projects' && projectsHovered)) && !recentProjectsOpen"
										@click.stop="toggleRecents">
										<img src="../assets/arrow.svg" alt="arrow icon" style="margin-left: 1px;">
									</div>
									<div v-show="recentProjectsOpen" class="arrowContainer"
										:style="recentProjectsOpenStyle" @click.stop="toggleRecents">
										<img src="../assets/arrow.svg" alt="arrow icon"
											style="margin-left: 1px; transform: rotate(90deg);">
									</div>
								</div>
							</div> -->
							<div class="recents" v-show="recentProjectsOpen">
								<span v-for="item, index in store.state.recentProjects" :key="index"
									style="width: 100%;">
									<div class="recentItem" @click="openRecentProject(item)">
										<img src="../assets/folderIconProjects.svg" alt="folder icon"
											style="position: absolute; margin-left: 9.5px;">
										<div class="underlineText" style="margin-left: 56px;">{{ item.project_name }}
										</div>
									</div>
									<div class="recentSpacer"></div>
								</span>
							</div>
							<div class="navSpacer"></div>
							<!-- <router-link to="/scenes" v-show="!store.state.projectFilesUploading" draggable="false"
            class="routerLink" id="routerLink3" @click="store.methods.clickScenes()">
            <div :class="{ navItem: store.state.pageActive !== 'scenes' || store.state.projectOpen,
              navItemActive: store.state.pageActive === 'scenes' && !store.state.projectOpen }">
              <div class="navIcon">
                <img src="../assets/scenesicon.svg" alt="scenes icon">
              </div>
              <div class="underlineText navItemText">Scenes</div>
            </div>
          </router-link> -->
							<div class="routerLink" id="routerLink1" draggable="false"
								v-show="store.state.projectFilesUploading" @click="alertUser">
								<div
									:class="{ navItem: store.state.pageActive !== 'home', navItemActive: store.state.pageActive === 'home' }">
									<div class="navIcon">
										<img src="../assets/homeicon.svg" alt="home icon">
									</div>
									<div class="underlineText navItemText">Home</div>
								</div>
							</div>
							<div class="routerLink" id="routerLink2" @click="alertUser"
								v-show="store.state.projectFilesUploading" draggable="false">
								<div
									:class="{ navItem: store.state.pageActive !== 'projects', navItemActive: store.state.pageActive === 'projects' }">
									<div class="navIcon">
										<img src="../assets/projectsicon.svg" alt="projects icon">
									</div>
									<div class="underlineText navItemText">Projects</div>
									<div class="arrowContainer"
										v-show="projectsHovered || (store.state.pageActive === 'projects' && projectsHovered)">
										<img src="../assets/arrow.svg" alt="arrow icon" style="margin-left: 1px;">
									</div>
								</div>
							</div>
							<div class="navSpacer"></div>
							<!-- <div v-show="store.state.projectFilesUploading" class="routerLink" draggable="false"
            id="routerLink3" @click="alertUser">
            <div :class="{ navItem: store.state.pageActive !== 'scenes', navItemActive: store.state.pageActive === 'scenes' }">
              <div class="navIcon">
                <img src="../assets/scenesicon.svg" alt="scenes icon">
              </div>
              <div class="underlineText navItemText">Scenes</div>
            </div>
          </div> -->
						</div>
					</div>
				</div>
				<router-link to="/manageteam" class="manageTeamButton" v-if="store.state.isUserAdmin"
					@click="store.methods.activateManageTeam(true)">
					<img src="../assets/settingsicon.svg" alt="manage teams icon" style="margin-left: 31px;">
					<span class="manageTeamText">Manage Team</span>
				</router-link>
			</nav>
		</div>
	</div>
</template>

<script setup>

import {
	onMounted, ref, watch, computed, inject
} from 'vue';
// import {
// 	useRouter
// } from 'vue-router';

const store = inject('store');
// const router = useRouter()

const projectsHovered = ref(false);
watch(store.state, (data) => {
	if (data.pageActive === 'projects') projectsHovered.value = true;
	else projectsHovered.value = false;
});

const recentProjectsOpen = ref(false);
// const toggleRecents = () => {
// 	recentProjectsOpen.value = !recentProjectsOpen.value;
// };
// const recentProjectsOpenStyle = computed(() => {
// 	if (recentProjectsOpen.value && store.state.pageActive === 'projects') return { background: '#FFF' };
// 	return {};
// });
const openRecentProject = (item) => {
	store.methods.openProject(item.project_name, item.unique_project_id, this.getThumbnail(item, false));
	recentProjectsOpen.value = false;
};
// const clickProjects = () => {
// 	router.push('projects');
// 	store.methods.updatePage('projects');
// };



const alertUser = () => {
	// eslint-disable-next-line
	window.alert('Please refresh browser tab to stop upload process.');
};

const teamSelectorOpen = ref(false);
const toggleTeamSelector = () => {
	if (!teamSelectorOpen.value) teamSelectorOpen.value = true;
	else teamSelectorOpen.value = false;
};
const dropdownSelectorStyle = computed(() => {
	if (teamSelectorOpen.value === true) return { borderRadius: '3px 3px 0 0 ' };
	return {};
});

const dropdownItems = ref([]);
const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min) + min);
const randomNumber = getRandomNumber(1, 21);

onMounted(() => {
	let currentPage = window.location.href.split('/');
	currentPage = currentPage[currentPage.length - 1];
	if (currentPage === 'projects' || currentPage === 'scenes' || currentPage === 'manageteam') store.methods.updatePage(currentPage);
	else store.methods.updatePage('home');
	for (let i = 0; i <= randomNumber; i += 1) {
		dropdownItems.value.push(i);
	}
});

</script>

<style scoped>
.recentSpacer {
	height: 1.5px;
}

.recentItem {
	height: 40px;
	width: 100%;
	border-radius: 10px;
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
}

.recentItem:hover {
	background: #eee;
}

.recents {
	height: 207.5px;
	width: 217.5px;
	margin-left: 18.5px;
	margin-top: 3px;
	display: flex;
	flex-direction: column;
}

.manageTeamText {
	height: 23px;
	color: #1C1C1C;
	font-family: 'GT-America-Medium';
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	margin-left: 14px;
	margin-top: 4px;
	text-decoration: none;
}

.manageTeamText:hover {
	text-decoration: none;
}

.manageTeamButton {
	position: absolute;
	bottom: 60px;
	height: 46px;
	width: 100%;
	background: rgba(85, 155, 245, 0.1);
	cursor: pointer;
	display: flex;
	align-items: center;
	text-decoration: none;
}

.manageTeamButton:hover {
	background: #009DF8AA;
}

.manageTeamButton:hover:active {
	background: #009DF8CC;
}

.teamDropdownItem {
	height: 56px;
	display: flex;
	align-items: center;
	cursor: default;
	position: relative;
}

.teamDropdownItem:hover {
	background: rgba(96, 58, 150, 0.1);
}

.dropdownScroll {
	flex: 0 0 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

.dropdownContainerBottom {
	border-top: 1px solid #E6E6E6;
	flex: 0 0 4px;
	background: #FFF;
	border-radius: 0 0 10px 10px;
}

.teamDropdownContainer {
	height: 225px;
	width: 236px;
	position: absolute;
	top: 114px;
	z-index: 5;
	background: #FFF;
	display: flex;
	flex-direction: column;
	border-radius: 0 0 10px 10px;
}

.selectorTitle {
	color: #1C1C1C;
	font-family: 'GT-America-Regular';
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
}

.selectorTextContainer {
	width: 198px;
	height: 33px;
	left: 15px;
	position: absolute;
	display: flex;
	flex-direction: column;
}

.selectorArrow {
	transform: scale(0.5);
}

.arrowSelector {
	height: 32px;
	width: 9px;
	display: flex;
	flex-direction: column;
	margin-right: 14px;
}

.headerOption:hover {
	text-decoration: underline;
}

.headerOption {
	height: 100%;
	color: #009DF8;
	font-family: 'GT-America-Regular';
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	cursor: pointer;
}

.arrowContainer {
	height: 26px;
	width: 26px;
	border-radius: 40px;
	margin-right: 7px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.arrowContainer:hover {
	background: #FFF;
}

.navIcon {
	height: 23px;
	width: 24px;
	position: absolute;
	left: 9.5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navSpacer {
	position: relative;
	height: 4px;
	width: 100%;
}

.teamDropdown {
	height: 70px;
	width: 236px;
	border-radius: 10px;
	background-color: #FFF;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: right;
	cursor: pointer;
}

.routerLink {
	height: 49px;
	width: 237px;
	position: relative;
}

.navItemText {
	position: absolute;
	top: 16px;
	left: 59px;
}

.navItem {
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: right;
}

.navItemActive {
	background: #eee;
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: right;
}

.navItemActive:hover {
	background: rgb(228, 228, 228);
}

.navItem:hover {
	background: #eee;
}

.navBar {
	height: 100%;
	width: 270px;
	position: relative;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	flex-flow: row nowrap;
	justify-content: flex-start;
	background: #F6F6F6;
}

.navContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

#mobileAuthNavBar {
	min-height: 125px;
	justify-content: space-between;
}
</style>
