import { createApp } from 'vue'
import {createRouter,createWebHistory} from 'vue-router'
import App from './App.vue'
import Scenes from './views/TeamScenes.vue';
import UserProfile from './views/UserProfile.vue';
import Projects from './views/TeamProjects.vue';
import PageNotFound from './views/PageNotFound.vue';
import ManageTeam from './views/ManageTeam.vue';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { authGuard } from '@auth0/auth0-vue';
import { createAuth0 } from "@auth0/auth0-vue";


const routes = [
    {
      path: '/scenes',
      name: 'scenes',
      component: Scenes,
      beforeEnter: authGuard
    },
    {
      path: '/profile',
      name: 'profile',
      component: UserProfile,
      beforeEnter: authGuard
    },
    {
      path: '/projects',
      component: Projects,
      beforeEnter: authGuard
    },
    {
      path: '/home',
      component: Projects,
      beforeEnter: authGuard
    },
    {
      path: '/',
      component: Projects,
      beforeEnter: authGuard
    },
    {
      path: '/notfound',
      name: 'not found',
      component: PageNotFound
    },
    {
      path: '/manageteam',
      name: 'manage team',
      component: ManageTeam,
      beforeEnter: authGuard
    },
  ];


const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})


const app = createApp(App).use(createAuth0({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    redirect_uri: window.location.origin + "/home",
    cookieDomain: '.scenik.io'
  }
}))

console.log(window.location.origin + "/home");

// app.use(
//   auth0
// );

app.use(router)
app.use(ElementPlus)

app.mount('#app')
