// --legacy-peer-deps
/* eslint-disable max-len */
import {
	reactive, watch, ref, readonly
} from 'vue';

// import { useRouter } from 'vue-router';
import axios from 'axios';
import en from 'javascript-time-ago/locale/en.json';
import TimeAgo from 'javascript-time-ago';

// let router;// = useRouter();

const routeData = reactive({ path: {} });


// Create formatter (English).
TimeAgo.addDefaultLocale(en);
let nonReactiveCopyOfProjects;

const state = reactive({
	allProjects: [],
	allScenes: [],
	recentProjects: [],
	projectScenes: [],
	allProjectFiles: [],
	newProjectVisible: false,
	pageActive: 'home',
	showContentShadow: false,
	searchStr: '',
	projectOpen: false,
	projectOpenName: '',
	projectSelectedID: '',
	projectOpenImageUrl: '',
	editingProject: false,
	editingProjectName: '',
	editingProjectObj: {},
	project_thumbnail: '',
	importFromUnreal: true,
	mainViewActive: true,
	projectFilesExist: false,
	renderRequestID: null,
	renderSessionID: null,
	renderInterval: null,
	sceneObjSelected: null,
	sessionEndpoint: process.env.VUE_APP_SESSION_REDIRECT,
	baseApiStr: process.env.VUE_APP_BASE_ENDPOINT,
	opensceneApiStr: process.env.VUE_APP_BASE_ENDPOINT,
	assetEndpoint: process.env.VUE_APP_ASSET_ENDPOINT,
	profileImageEndpoint: process.env.VUE_APP_PROFILE_IMAGE_ENDPOINT,
	newProjectId: '',
	newProject: false,
	filesLoading: false,
	newProjectLoading: false,
	projectFilesUploading: false,
	allProjectsLoaded: false,
	statusModalVisible: false,
	projectSyncStatus: false,
	projectDeleteStatus: false,
	renderSessionStatus: '',
	projectLoadingStatus: '',
	deleteStr: '',
	ddcModalVisible: false,
	allProjectsArchived: true,
	profileSettingsEnabled: false,
	manageTeamActive: false,
	isUserAdmin: false,
	newProfileImage: false,
	newProfileImageURL: '',
	headerDropdownVisible: false,
	contentWidth: 0,
	totalScrollProjects: 0,
	projectSearchActive: false,
	sceneSearchActive: false,
	selectorViewActive: 'recent',
	uploadFile: '',
	tempStatus: 1,
	accessToken: null,
	shareModalVisible: false,
	teamLicenseData: {},
	worldOwnerProfile: {},
	shareWorld: {},
	// current users the world is shared with
	worldShareUsers: [],
	worldSharedAll: false,
});

const copyOfProjectFiles = ref([]);

watch(state, (data) => {
	data.allProjects.forEach((project) => {
		if (project.visible === true) state.allProjectsArchived = false;
	});
	state.totalScrollProjects = (data.allProjects.length * 274) + 7;
});

const methods = {
	async updateAccessToken(token) {
		state.accessToken = await token;
	},
	updateUploadFile(file) {
		state.uploadFile = file;
	},
	updateRecentProjects(arr) {
		state.recentProjects = arr;
	},
	updateSelectorView(str) {
		state.selectorViewActive = str;
	},
	disableActiveSearch() {
		state.searchStr = '';
		state.sceneSearchActive = false;
		state.projectSearchActive = false;
	},
	toggleActiveSearch(str) {
		if (str === 'projects') {
			state.searchStr = '';
			state.sceneSearchActive = false;
			state.projectSearchActive = true;
		}
		if (str === 'scenes') {
			state.searchStr = '';
			state.projectSearchActive = false;
			state.sceneSearchActive = true;
		}
	},
	updateContentWidth(num) {
		state.contentWidth = num;
	},
	updateHeaderDropdownVisible(bool) {
		state.headerDropdownVisible = bool;
	},
	updateNewProfileImageURL(data) {
		state.newProfileImageURL = data;
	},
	enableNewProfileImage(bool) {
		state.newProfileImage = bool;
	},
	activateManageTeam(bool) {
		if (bool) this.updatePage('manageteam');
		else this.updatePage('home');
	},
	enableProfileSettings(bool) {
		state.profileSettingsEnabled = bool;
	},
	clickScenes() {
		if (state.projectOpen) this.updatePage('projects');
		this.updatePage('scenes');
	},
	toggleDdcMissingModal(bool) {
		state.ddcModalVisible = bool;
	},
	setDeleteStr(str) {
		state.deleteStr = str;
	},
	deleteProject() {
		state.statusModalVisible = false;
		state.projectDeleteStatus = false;
		this.setDeleteStr('');
		this.updateProject('update', state.editingProjectObj.unique_project_id, state.editingProjectObj.allProjectsIndex, false, true);
		this.updatePage('home');
	},
	deleteProjectConfirm() {
		state.statusModalVisible = true;
		state.projectDeleteStatus = true;
	},
	setStatusModal(boolVar) {
		this.setDeleteStr('');
		state.statusModalVisible = boolVar;
	},
	toggleFilesUploading(bool) {
		state.projectFilesUploading = bool;
		if (bool === true) state.filesLoading = false;
	},
	toggleFilesLoading(bool) {
		state.filesLoading = bool;
		// console.log('files loading bool:', state.filesLoading);
	},
	addProject(initProject = false) {
		if (!initProject)
			state.newProjectLoading = true;
		this.apiCallUpdateProject('add', undefined, false, false, false, undefined, state.editingProjectName).then((message) => {
			const messageData = JSON.parse(JSON.stringify(message.data));
			state.newProjectId = messageData.unique_project_id;
			this.apiCallAllProjects().then((message1) => {
				this.updateProjects(message1.data);
				const newProjectObj = state.allProjects.filter((project) => project.unique_project_id === state.newProjectId)[0];
				state.editingProjectObj = newProjectObj;
				// console.log('new project object:', newProjectObj);
				if (!initProject)
					this.toggleNewProjectVisibility(false, newProjectObj, true);
			});
			console.log('add project response:', messageData.response);
		});
	},
	updateProjectThumbnailFromBase64(str) {
		state.project_thumbnail = `data:image/png;base64,${str}`;
	},
	updateProject(operation, id, projectIndex, archive, deleteVar = false, thumbnailBoolean, thumbnailImg, name) {
		if (thumbnailImg !== undefined) {
			state.allProjects[projectIndex].thumbnail_img = thumbnailImg;
		}
		if (name !== undefined) {
			state.allProjects[projectIndex].project_name = name;
		}
		// if (thumbnailBoolean !== undefined) {
		//   state.allProjects[projectIndex].userthumbnail = thumbnailBoolean;
		// }
		// if (archive === false) {
		//   state.allProjects[projectIndex].scenes.forEach((scene) => {
		//     state.allScenes.push(scene);
		//   });
		// }
		if (archive === true) {
			state.allProjects[projectIndex].visible = false;
			state.allScenes = state.allScenes.filter((scene) => scene.unique_project_id !== state.allProjects[projectIndex].unique_project_id);
		} else if (deleteVar === false) {
			state.allProjects[projectIndex].visible = true;
		}
		if (deleteVar === true) {
			state.allProjects.splice(projectIndex, 1);
		}
		this.apiCallUpdateProject(operation, id, archive, deleteVar, thumbnailBoolean, thumbnailImg, name).then((message) => {
			console.log('update project response:', message);
			this.apiCallAllProjects().then((message) => {
				if (message.data) {
					this.updateProjects(message.data);
				}
			});
		});
	},
	openScene(scene) {
		//Set appId in case we need it for redirect after login
		axios.post(`${state.baseApiStr}/updateuser`, { appId: scene.unique_project_id},
			{
				headers: {
					authorization: `Bearer ${state.accessToken}`,
				},
			}).then(() => {
				window.location.replace(`${state.sessionEndpoint}/?appId=${scene.unique_project_id}`);
			}).catch((error) => {
				console.log('update auth user api response:', error);
			});
	},
	getThumbnail(obj) {
		var standardProjectUrl = state.assetEndpoint + obj.unique_project_id + '/' + obj.image_path;
		return standardProjectUrl;
	},
	getRenderStatus() {
		if (state.tempStatus === 4) state.tempStatus = 0;
		state.tempStatus += 1;
		let status = 'Getting your scene ready';
		for (let i = 0; i < state.tempStatus; i += 1) {
			status += '.';
		}
		return status;
	},
	updateScene(id, visible, sceneIndex, projectSceneIndex) {
		console.log('sceneIndex in update scene func:', sceneIndex);
		if (visible === true && Number.isInteger(projectSceneIndex)) state.projectScenes[projectSceneIndex].visible = true;
		// console.log(state.allScenes);
		if (visible === true) state.allScenes[sceneIndex].visible = true;
		if (visible === false && Number.isInteger(projectSceneIndex)) state.projectScenes[projectSceneIndex].visible = false;
		if (visible === false) state.allScenes[sceneIndex].visible = false;
		this.apiCallUpdateScene(id, visible).then((message) => {
			console.log('update scene response:', message);
		});
	},
	setImportFromUnreal() {
		state.importFromUnreal = !state.importFromUnreal;
	},
	setProjectName(str) {
		state.editingProjectName = str;
	},
	openProject(projectname, uniqueId, imageUrl) {
		state.projectOpenName = projectname;
		state.projectSelectedID = uniqueId;
		state.projectOpenImageUrl = imageUrl;
		let projectData = nonReactiveCopyOfProjects;
		projectData = projectData.filter((project) => project.project_name === projectname)[0].scenes;
		for (let i = 0; i < projectData.length; i += 1) {
			projectData[i].projectSceneIndex = i;
		}
		// console.log('non reactive copy of project scenes:', projectData);
		state.projectScenes = projectData;
		state.projectOpen = true;
		this.updatePage('scenes');
	},
	setSearchStr(str) {
		state.searchStr = str;
	},
	getSceneName(name) {
		let processingName;
		processingName = name.split('\\');
		processingName = processingName[processingName.length - 1].split('.');
		const nameToReturn = processingName[0];
		return nameToReturn;
	},
	toggleContentShadow(num) {
		// eslint-disable-next-line
		num >= 30 ? state.showContentShadow = true : state.showContentShadow = false;
	},
	updateMainView(bool) {
		state.mainViewActive = bool;
	},
	updatePage(str) {

		if (str !== 'scenes') state.projectOpen = false;
		if (str === 'projects' || str === 'scenes' || str === 'home' || str === 'manageteam') {
			state.newProjectVisible = false;

			this.updateMainView(true);
			state.editingProject = false;
			state.editingProjectName = '';
			state.project_thumbnail = '';
			state.editingProjectObj = {};
			state.newProjectVisible = false;
			state.importFromUnreal = true;
			state.projectFilesExist = false;
			state.allProjectFiles = [];
			this.toggleFilesLoading(false);
			state.selectorViewActive = 'recent';
			state.manageTeamActive = (str == 'manageteam');
		}
		state.showContentShadow = false;
		state.pageActive = str;
		state.searchStr = '';
		if (document.getElementById('content')) document.getElementById('content').scrollTop = 0;
	},
	updateProjects(data) {
		state.allProjectsLoaded = false;
		const projectData = data;
		for (let i = 0; i < projectData.length; i += 1) {
			projectData[i].allProjectsIndex = i;
		}
		nonReactiveCopyOfProjects = projectData;

		// Get images already received
		if (Object.keys(projectData).length > 0) {
			if (state.allProjects) {
				for (let i = 0; i < nonReactiveCopyOfProjects.length; i += 1) {
					const proj = state.allProjects.find((x) => x.unique_project_id === projectData[i].unique_project_id);
					if (proj) {
						if (proj.thumbnail_img) {
							projectData[i].thumbnail_img = proj.thumbnail_img;
						}
						if (proj.unreal_thumbnail_img) {
							projectData[i].unreal_thumbnail_img = proj.unreal_thumbnail_img;
						}
					}
				}
			}
			state.allProjects = projectData;
			let scenes = [];
			projectData.forEach((project) => {
				// console.log(JSON.stringify(project));
				const prj = state.allProjects.find((x) => x.unique_project_id === project.unique_project_id);
				if (prj.thumbnail_img) {
					prj.thumbnail_img = `${state.assetEndpoint}/assets/projects_user/${project.unique_project_id}.png`;
				}

				if (prj.unreal_thumbnail_img) {
					prj.unreal_thumbnail_img = `${state.assetEndpoint}/assets/projects_unreal/${project.unique_project_id}.png`;
				}

				// project.scenes.forEach((scene) => {
				//   const sceneCopy = scene;
				//   sceneCopy.unique_project_id = project.unique_project_id;
				//   sceneCopy.project_name = project.project_name;
				//   if (scene.thumbnail_img) {
				//     sceneCopy.thumbnail_img = `${state.assetEndpoint}/assets/scenes/${scene.unique_scene_id}.png`;
				//   } else {
				//     sceneCopy.thumbnail_img = null;
				//   }
				//   scenes.push(sceneCopy);
				// });
			});
			for (let i = 0; i < scenes.length; i += 1) {
				scenes[i].allScenesIndex = i;
			}
			const archivedProjects = state.allProjects.filter((project) => project.visible === false);
			archivedProjects.forEach((project) => {
				scenes = scenes.filter((scene) => scene.unique_project_id !== project.unique_project_id);
			});
			state.allScenes = scenes;
			state.allProjectsLoaded = true;
			// console.log('all projects loaded:', state.allProjectsLoaded);
			console.log('all scenes: ', scenes);
			console.log('all projects: ', projectData);
		} else {
			state.allProjectsLoaded = true;
			console.log('projectData is empty');
		}
	},
	shareProjectModal(world) {
		// hide settings/share dropdown
		this.apiCallGetUser(world.owner_id).then((response) => {
			state.worldOwnerProfile = response.data;
		});

		console.log('show share modal');
		console.log(state.worldSharedAll);

		this.getShareAllByAppId(world.unique_project_id).then((response) => {
			if (response.data) {
				state.worldSharedAll = response.data.shared;
			}
		});

		this.getSharesByAppId(world.unique_project_id).then((response) => {
			if (response.data) {
				state.worldShareUsers = response.data;
			}
		});

		const dropdowns = document.querySelectorAll('.dropdown__list')
		dropdowns.forEach(item => item.classList.add('hide'))

		state.shareModalVisible = true;
		state.shareWorld = world;

	},
	setTeamLicenseData(data) {
		state.teamLicenseData = data;
	},
	hideShareModal() {
		state.shareModalVisible = false;
		state.worldSharedAll = false;
		state.worldShareUsers = [];
		state.shareWorld = {};
		state.worldOwnerProfile = {};

		console.log('hide modal');
		console.log(state.worldSharedAll);
	},
	async getShareAllByAppId(appId) {
		let data;

		try {
			data = await
				axios.get(`${state.baseApiStr}/getshareallproject?appId=${appId}`, {
					headers: {
						Authorization: `Bearer ${state.accessToken}`,
					},
				});
		} catch (e) {
			console.log(`Error: the server responded with '${e.code}: ${e.message}'`);
		}
		return data;
	},

	async getSharesByAppId(appId) {
		let data;

		try {
			data = await
				axios.get(`${state.baseApiStr}/getsharesproject?appId=${appId}`, {
					headers: {
						Authorization: `Bearer ${state.accessToken}`,
					},
				});
		} catch (e) {
			console.log(`Error: the server responded with '${e.code}: ${e.message}'`);
		}
		return data;
	},
	async apiCallAllProjects() {
		let data;

		try {
			data = await
				axios.get(`${state.baseApiStr}/getallprojectsforuser`, {
					headers: {
						Authorization: `Bearer ${state.accessToken}`,
					},
				});
		} catch (e) {
			console.log(`Error: the server responded with '${e.code}: ${e.message}'`);
		}
		return data;
	},
	async apiCallUpdateProject(operation, projectId, archive = false, deleteProject = false, thumbnailFromUnreal = false, thumbnail, projectName) {
		let data;
		let getStr;
		let thumbnailObj = {};
		// let user = auth0.user;
		// const AuthStr = user.value.sub;

		if (operation === 'add') {
			getStr = `${state.baseApiStr}/addorupdateproject?operation=${operation}&projectname=${projectName}`;
		}
		if (operation === 'update') {
			if (thumbnail === undefined && projectName === undefined) {
				getStr = `${state.baseApiStr}/addorupdateproject?operation=${operation}&projectuniqueid=${projectId}&archiveproject=${archive}&deleteproject=${deleteProject}&thumbnailfromunreal=${thumbnailFromUnreal}`;
			} else {
				if (thumbnail !== undefined) thumbnailObj = { thumbnailImage: thumbnail.replace('data:image/png;base64', '') };
				getStr = `${state.baseApiStr}/addorupdateproject?operation=${operation}&projectuniqueid=${projectId}&archiveproject=${archive}&deleteproject=${deleteProject}&thumbnailfromunreal=${thumbnailFromUnreal}&projectname=${projectName}`;
			}
		}
		try {
			data = await
				axios.post(getStr,
					thumbnailObj,
					{
						headers: {
							Authorization: `Bearer ${state.accessToken}`,
						},
					});
		} catch (e) {
			console.log('api update project error');
			console.log(`Error: the server responded with '${e.response.status}: ${e.response.statusText}'`);
		}
		return data;
	},
	async apiCallUpdateScene(sceneID, sceneVisible) {
		let data;
		try {
			data = await
				axios.get(`${state.baseApiStr}/updatescene?sceneuniqueid=${sceneID}&scenevisible=${sceneVisible}`, {
					headers: {
						Authorization: `Bearer ${state.accessToken}`,
					},
				});
		} catch (e) {
			console.log('api update scene error');
			console.log(`Error: the server responded with '${e.response.status}: ${e.response.statusText}'`);
		}
		return data;
	},
	async apiCallGetUsersForTeam() {
		let data;
		try {
			data = await
				axios.get(`${state.baseApiStr}/getteambyuser`, {
					headers: {
						Authorization: `Bearer ${state.accessToken}`,
					},
				});
		} catch (e) {
			console.log('api call project files error');
			console.log(`Error: the server responded with '${e.response.status}: ${e.response.statusText}'`);
		}
		return data;
	},
	async apiCallGetUser(userid) {
		let data;
		try {
			data = await
				axios.get(`${state.baseApiStr}/getuser?userid=${userid}`, {
					headers: {
						Authorization: `Bearer ${state.accessToken}`,
					},
				});
		} catch (e) {
			console.log('api call project files error');
			console.log(`Error: the server responded with '${e.response.status}: ${e.response.statusText}'`);
		}
		return data;
	},
	getBase64Image(imgUrl) {
		return new Promise((resolve) => {
			const img = new Image();
			img.src = imgUrl;
			img.setAttribute('crossOrigin', 'anonymous');
			img.onload = (() => {
				const canvas = document.createElement('canvas');
				canvas.width = img.width;
				canvas.height = img.height;
				const ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0);
				const dataURL = canvas.toDataURL('image/png');
				// console.log('UgetBase64Image.dataURL ', dataURL);
				resolve(dataURL.replace(/^data:image\/(png|jpg);base64,/, ''));
			});
		});
	},
	toggleNewProjectVisibility(archived = null, obj = null, newProject = false) {
		state.filesLoading = false;
		if (archived !== null && obj !== null) {
			state.newProject = newProject;
			state.editingProject = true;
			// state.importFromUnreal = !obj.userthumbnail;
			state.editingProjectName = obj.project_name;
			if (!newProject) {
				// Always convert this to base64 so that the base64 data is ready for updating the project
				state.project_thumbnail = this.getThumbnail(obj);
			} else {
				state.newProjectLoading = false;
			}
			state.archivedProject = archived;
			state.editingProjectObj = obj;
			this.updatePage('editing project');
		} else {
			//New Project immediately saves
			//this.updatePage('editing project');
			state.editingProjectName = 'Untitled ' + this.formatDate(new Date());
			this.updatePage('new project');
			state.editingProject = true; //All new projects are immediately saved
			this.addProject(true);
		}
		state.newProjectVisible = true;
		// console.log('changed new project visibility: ', state.newProjectVisible);
	},
	formatDate(date) {
		let datePart = [
			date.getMonth() + 1,
			date.getDate(),
			date.getFullYear()
		].map((n, i) => n.toString().padStart(i === 2 ? 4 : 2, "0")).join("-");

		let timePart = [
			date.getHours(),
			date.getMinutes(),
			date.getSeconds()
		].map((n) => n.toString().padStart(2, "0")).join(":");

		return datePart + " " + timePart;
	},
	updateIsAdminUser(bool) {
		state.isUserAdmin = bool;
	}
};



watch(routeData, (data) => {
	methods.updatePage(data.path);
	if (data.path !== 'loading') {
		// Stop trying to enter a live session if loading screen is exited
		clearInterval(state.renderInterval);
	}
});

export default {
	state: readonly(state), methods, TimeAgo, copyOfProjectFiles,
	mounted() {
	},
	setup() {
		// router = useRouter();

	}
};
