<template>
	<div>
		<div class="modalContainer" id="shareModalContainer">
			<div class="shareHeader">
				<div class="header">
					<p class="headerText">Share {{ world.project_name }}</p>
				</div>
				<div class="closeShare" @click.stop="closeShareModal()">
					<img src="../assets/CloseShare.svg">
				</div>
			</div>

			<div class="toggleContainer">
				<div class="toggle">
					<input class="toggleAll" type="checkbox" id="temp" v-model="state.shareAll"
						@change="shareAllUpdate()">
					<label for="temp"> &nbsp; Share with everyone at {{ store.state.teamLicenseData.team_name }}</label>
				</div>
			</div>

			<div class="inviteContainer">
				<div class="inputContainer" @click="focusEmailInput">
					<input type="text" name="inviteuser" v-model="state.emailInput" @keyup="onKeyUp"
						placeholder="Email, comma separated" ref="emailTextArea" />
				</div>

				<div class="inviteButton" @click="methods.inviteClicked">
					<span>{{ state.inviteButton }}</span>
				</div>
			</div>

			<div class="emailsContainer" v-show="state.emails.length > 0">
				<div v-for="(user, index) in state.emails" :key="index" class="emailItem">
					{{ user.email }}
					<img src="../assets/AddingAUser.svg" class="shareInviteUser"
						v-show="user.name == '' || user.same_team == false" />
					<button @click="removeEmail(index, user)" class="removeBtn"><img
							src="../assets/RemoveEmail.svg"></button>
				</div><br />
			</div>

			<div class="licenseContainer">
				<div style="display:flex;">
					<div v-bind:class="{ 'licAvailableAdded': state.guestAccounts > 0 }">
						Licenses Available: {{ state.leftLicenses }}/{{ state.maxLicenses }}
					</div>
					<div class="availLicenses" v-show="state.noLicensesAvailable">
						No available licenses
					</div>
					<div class="availLicenses" v-show="state.userAlreadyExists">
						This user already has access
					</div>
					<div class="inviteSent" v-show="state.inviteSent">
						Invite Sent
					</div>
				</div>
			</div>

			<div class="invitedUsers">
				<div class="userBox" v-show="state.worldOwner.picture">
					<div class="userProfileIcon">
						<img class="uprofileIcon" v-bind:src="state.worldOwner.picture" />
					</div>
					<div class="userName">
						<span class="name">{{ state.worldOwner.email }}</span>&nbsp;
						<span class="status">(Owner<span v-show="world.o_owner">, you</span>)</span>
					</div>
				</div>
				<div v-for="(invite, index) in shareInvites" :key="index" class="userBox">
					<div class="userProfileIcon">
						<img class="uprofileIcon" src="../assets/Person.svg" v-show="!invite.picture" />
						<img class="uprofileIcon" v-bind:src="invite.picture" v-show="invite.picture" />
					</div>
					<div class="userName">
						<span class="name">{{ invite.email }}</span>&nbsp;
						<!-- <span class="status" v-show="invite.status == 'pending'">Pending</span> -->
					</div>
					<div class="inviteUser" @click="methods.makeShareDeleted(invite.id)">
						Remove
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script setup>

import {
	inject,
	reactive,
	defineProps,
	watch
} from 'vue';
import axios from 'axios';

const store = inject('store');

const state = reactive({
	guestAccounts: 0,
	usedLicenses: 0,
	leftLicenses: 0,
	maxLicenses: 0,
	maxEmails: 0,
	usedDataLicenses: 0,
	noLicensesAvailable: false,
	userAlreadyExists: false,
	inviteSent: false,
	emailInput: '',
	shareAll: false,
	worldOwner: {},
	emails: [],
	inviteButton: "Invite",
});

const props = defineProps({
	licenses: {
		type: Object,
		default: () => { }
	},
	world: {
		type: Object,
		default: () => { }
	},
	ownerProfile: {
		type: Object,
		default: () => { }
	},
	shareInvites: {
		type: Array,
		default: () => []
	},
	shareAll: {
		type: Boolean,
		default: () => false
	}
});

const onKeyUp = (event) => {
	if (event.key === 'Enter' || event.key === ',') {
		methods.addEmail();
	}
};

const removeEmail = (index, email) => {
	if (email.unique_user_id == -1) {
		state.usedLicenses--;
		state.guestAccounts--;
		state.leftLicenses = state.maxLicenses - state.usedLicenses;
	}

	state.emails.splice(index, 1);
};

const shareAllUpdate = () => {
	methods.shareWithAllProject();
};

const closeShareModal = () => {
	state.shareAll = false;
	store.methods.hideShareModal();
};

watch(() => props.shareAll, (data) => {
	state.shareAll = data;
});

watch(() => props.licenses, () => {
	state.maxLicenses = store.state.teamLicenseData.licenses;
	state.usedLicenses = store.state.teamLicenseData.licenses_used;
	state.usedDataLicenses = state.usedLicenses;
	state.maxEmails = state.maxLicenses - state.usedLicenses;

	state.leftLicenses = state.maxLicenses - state.usedLicenses;
});

watch(() => props.ownerProfile, (owner) => {
	state.worldOwner = owner;
});

watch(() => state.emailInput, (input) => {
	if (input.length > 0) {
		state.inviteButton = "Add"
	} else {
		state.inviteButton = "Invite"
	}
});

watch(() => state.guestAccounts, (guestAccts) => {
	if (guestAccts >= state.maxEmails) {
		state.licenseWarning = true;
		state.noLicensesAvailable = true;
	} else {
		state.licenseWarning = false;
		state.noLicensesAvailable = false;
	}
})

const methods = {
	makeShareDeleted(shareId) {
		this.deleteUserShare(shareId);
	},
	async deleteUserShare(shareId) {
		let data;
		try {
			data = await
				axios.get(`${store.state.baseApiStr}/marksharedeleted?shareId=${shareId}`, {
					headers: {
						Authorization: `Bearer ${store.state.accessToken}`,
					},
				}).then(() => {
					store.methods.shareProjectModal(props.world);
				});

		} catch (e) {
			console.log(`Error: the server responded with '${e.code}: ${e.message}'`);
		}
		return data;
	},
	addEmail() {
		const emailParts = state.emailInput.split(',').map(email => email.trim());

		// todo: make sure it's a properly formatted email address?

		emailParts.forEach(email => {
			if (email.length > 0) {
				let findMatch = false;
				state.emails.forEach(invited => {
					if (invited.email == email) {
						findMatch = true;
						return;
					}
				});
				props.shareInvites.forEach(invited => {
					if (invited.email == email) {
						findMatch = true;
						return;
					}
				});

				if (email == state.worldOwner.email) {
					findMatch = true;
				}

				if (!findMatch) {
					state.userAlreadyExists = false;
					methods.checkShareUser(email);
				} else {
					state.userAlreadyExists = true;
				}
			}
		});
		state.emailInput = '';
	},
	checkShareUser(email) {
		this.getUserByEmail(email).then((response) => {
			let data = response.data;

			if (!data && !state.licenseWarning) {
				state.guestAccounts++;
				state.usedLicenses++;
				state.emails.push({ unique_user_id: "-1", name: "", email: email, same_team: false });

				if (state.guestAccounts == state.maxEmails) {
					state.noLicensesAvailable = true;
				}
			} else {
				if (data.same_team == false && !state.licenseWarning) {
					state.guestAccounts++;
					state.usedLicenses++;
					state.emails.push(data);

					if (state.guestAccounts == state.maxEmails) {
						state.noLicensesAvailable = true;
					}
				} else if (data.same_team == true) {
					state.emails.push(data);
				}
			}
			state.leftLicenses = state.maxLicenses - state.usedLicenses;
		});
	},
	async getUserByEmail(shareEmail) {
		let data;

		try {
			data = await
				axios.get(`${store.state.baseApiStr}/getshareuser?email=${shareEmail}`, {
					headers: {
						Authorization: `Bearer ${store.state.accessToken}`,
					},
				});
		} catch (e) {
			console.log(`Error: the server responded with '${e.code}: ${e.message}'`);
		}
		return data;
	},
	async shareProject(payload) {
		let data;

		try {
			data = await
				axios.post(`${store.state.baseApiStr}/shareproject`,
					{
						appId: props.world.unique_project_id,
						emails: state.emails,
						shareAll: payload.shareAll
					},
					{
						headers: {
							Authorization: `Bearer ${store.state.accessToken}`,
						},
					});
		} catch (e) {
			console.log(`Error: the server responded with '${e.code}: ${e.message}'`);
		}
		return data;
	},
	async shareWithAllProject() {
		let data;
		try {
			data = await
				axios.post(`${store.state.baseApiStr}/manageshareall`,
					{
						appId: props.world.unique_project_id,
						shareAll: state.shareAll
					},
					{
						headers: {
							Authorization: `Bearer ${store.state.accessToken}`,
						},
					});
		} catch (e) {
			console.log(`Error: the server responded with '${e.code}: ${e.message}'`);
		}
		return data;
	},
	inviteClicked() {
		this.addEmail();

		if (state.inviteButton == 'Invite') {
			if (state.emails.length > 0) {
				state.noLicensesAvailable = false;
				state.userAlreadyExists = false;
				state.inviteSent = true;

				setTimeout(() => {
					state.inviteSent = false;
				}, 3000);

				const payload = {
					shareAll: state.shareAll,
				};
				// todo return the shared accounts here and populate the share emails
				this.shareProject(payload).then(() => {
					state.emails = [];
					store.methods.shareProjectModal(props.world);
				});
			}
		}
	},
	updateShareInvites(index) {
		state.emails.splice(index, 1);
	},
};

</script>

<style lang="scss">
.shareModal {
	z-index: 10000;
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: #00000050;
}

/* Share Component */

.shareHeader {
	padding: 10px 0px 10px 0px;
	display: flex;
	width: 100%;
	align-content: space-between;
	justify-content: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.closeShare {
	margin-left: auto;
	padding-right: 20px;
	flex-direction: row-reverse;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	cursor: pointer;
}

.modalContainer {
	margin: 20px;
	min-height: 460px;
	width: 562px;
	border-radius: 20px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 150px;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 100000000;
	box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.5);
}

.header {
	padding-left: 20px;
}

.headerText {
	color: #009DF8;
	font-family: 'GT-America-Regular';
	font-size: 16px;
	font-weight: bold;
	line-height: 12px;
}


.inviteContainer {
	padding: 20px 0px 0px 0px;
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	align-content: space-between;
}

.inputContainer {
	border: 1px solid rgba(0, 0, 0, .15);
	border-radius: 10px;
	margin-right: 10px;
	margin-left: 20px;
	width: 420px;
	cursor: text;
}

.inputContainer input {
	border: 0px;
	padding-left: 15px;
	background: none;
	width: 400px;
	height: 30px;
	margin-top: 4px;
}

.inputContainer input:focus {
	outline: none;
}

.inviteButton {
	margin-left: auto;
	flex-direction: row-reverse;
	display: flex;
	width: 97px;
	height: 40px;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	background: var(--ScenikBlue, #009DF8);
	color: var(--ScenikWhite, #FFF);
	font-family: 'GT-America-Regular';
	font-size: 16px;
	cursor: pointer;
	margin-right: 20px;
}

.toggleContainer {
	padding: 0px 0px 20px 20px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.licenseContainer {
	padding: 10px 0px 10px 25px;
	font-family: 'GT-America-Medium';
	font-size: 12px;
	font-style: normal;
	font-weight: bold;
}

.availLicenses {
	margin-left: auto;
	padding-right: 20px;
	color: #ff5454;
}

.licAvailableAdded {
	color: #009DF8;
}

.inviteSent {
	margin-left: auto;
	padding-right: 20px;
	color: #000;
}

.toggle {

	padding-top: 20px;
	display: flex;

	input[type="checkbox"] {
		display: none;
	}

	label {
		color: #000;
		position: relative;
		display: flex;
		align-items: center;
		font-family: 'GT-America-Regular';
		font-size: 14px;
		font-style: normal;
	}

	input[type="checkbox"]+label::before {
		content: ' ';
		display: block;
		height: 26px;
		width: 50px;
		border-radius: 13px;
		background: #d9d9d950;
	}

	input[type="checkbox"]+label::after {
		content: ' ';
		display: block;
		height: 22px;
		width: 22px;
		border-radius: 50%;
		position: absolute;
		top: 2px;
		left: 3px;
		background: #858585;
		transition: all 0.1s ease-in;
	}

	input[type="checkbox"]:checked+label::after {
		left: 24px;
		transition: all 0.1s ease-in;
		background: #009Df8;
	}
}

.emailsContainer {
	padding-top: 10px;
	padding-left: 20px;
	display: inline-block;
	/* display: flex;
	flex-wrap: wrap; */
}

.shareInviteUser {
	position: relative;
	top: 3px;
	left: 4px;
}

.emailItem {
	border: 1px solid #848484;
	border-radius: 10px;
	/* display: flex; */
	display: inline-block;
	align-items: center;
	padding: 3px 5px 5px 8px;
	margin: 0px 4px 4px 0px;
	font-family: 'GT-America-Medium';
	font-size: 14px;

}

.removeBtn {
	border: none;
	color: #848484;
	cursor: pointer;
	margin-left: 13px;
	padding: 0 5px;
	background: none;
}

input::-webkit-input-placeholder {
	font-weight: bold;
	color: #ccc;
}

input:-moz-placeholder {
	font-weight: bold;
	color: #ccc;
}

input::-ms-input-placeholder {
	font-weight: bold;
	color: #ccc;
}

.removeBtn img {
	height: 10px;
	width: 10px;
	display: flex;
	align-items: center;
}

.toggleAll {
	cursor: pointer;
}

.invitedUsers {
	display: flex;
	flex-wrap: wrap;
	padding: 5px 20px;
}

.userBox {
	display: flex;
	/* border:1px solid #000; */
	width: 100%;
	margin-bottom: 10px;
}

.uprofileIcon {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	display: inline;
	vertical-align: middle;

}

.userProfileIcon {
	display: flex;
	align-items: center;
	padding-right: 10px;
}

.userName {
	display: flex;
	align-items: center;
}

.userName .name {
	font-family: 'GT-America-Regular';
	font-size: 16px;
}

.userName .status {
	font-family: 'GT-America-Regular';
	font-size: 16px;
	color: #00000050;
}

.inviteUser {
	margin-left: auto;
	flex-direction: row-reverse;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	height: 40px;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	background: var(--ScenikBlue, #009DF8);
	color: var(--ScenikWhite, #FFF);
	font-family: 'GT-America-Regular';
	font-size: 16px;
	cursor: pointer;
	width: 97px;
}
</style>