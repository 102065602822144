<template>
	<div class="childrenFill">
		<NavBar ref="navBar" />
		<div class="relativeDiv" style="overflow-y: hidden; display: flex; z-index: 5; overflow-x: hidden;">
			<PageHeader></PageHeader>
			<ProfileSettings v-show="store.state.profileSettingsEnabled"></ProfileSettings>
			<div class="relativeDiv" style="display: flex; align-items: center; flex-direction: column; position: relative; margin-top: 60px;
    position: absolute;">
				<div style="z-index: 5; position: absolute;"
					v-show="addMemberModalVisible && !store.state.profileSettingsEnabled && !store.state.headerDropdownVisible">
					<div class="body" :style="bodyStyle">
						<div class="addMemberModal">
							<div style="height: 56px; border-bottom: 0.5px solid rgba(133, 133, 133, 0.4);"
								class="flexDiv">
								<div class="addNewMemberTitle">Add new member</div>
								<img src="../assets/closeicon.svg" alt="close button"
									style="position: absolute; right: 28px; top: 22px; right: 22px;"
									@click="toggleAddMemberModal">
							</div>
							<div style="height: 373px; position: relative; padding: 19px;">
								<div style="height: 328px; margin-left: 0;">
									<div style="height: 83px; position: relative;">
										<div class="subSectionText">Name</div>
										<div :class="{ manageTeamSearchContainer: true, searchContainerActive: nameActive }"
											style="width: 100%; margin-top: 9.5px;">
											<input type="text" class="searchForTeamMemberInput" v-model="name"
												maxlength="25" @focus="nameActive = true" @blur="nameActive = false"
												spellcheck="false">
										</div>
										<div class="nameCounterText">{{ name.length }}/25</div>
									</div>
									<div style="margin-top: 3px; height: 64.5px;">
										<div class="subSectionText">Email</div>
										<div :class="{ manageTeamSearchContainer: true, searchContainerActive: emailActive }"
											style="width: 100%; margin-top: 9.5px;">
											<input type="text" class="searchForTeamMemberInput" v-model="email"
												@focus="emailActive = true" @blur="emailActive = false"
												spellcheck="false">
										</div>
									</div>
									<!-- <div style="height: 53px; margin-top: 19.5px;">
                <div class="subSectionText">Role</div>
                <div style="height: 17px; margin-top: 21px; display: flex; align-items: center;">
                  <div class="radioOutline" @click="selectRadio('member')">
                    <div class="radio" v-show="radioSelection === 'member'"></div>
                  </div>
                  <div class="radioText" @click="seleddorctRadio('member')">Team member</div>
                  <div class="radioOutline" style="position: absolute; left: 183px;" @click="selectRadio('guest')">
                    <div class="radio" v-show="radioSelection === 'guest'"></div>
                  </div>
                  <div class="radioText" style="position: absolute; left: 197px;" @click="selectRadio('guest')">Guest</div>
                </div>
              </div> -->
									<div style="height: 40px; display: flex; justify-content: left; margin-top: 15px;">
										<button class="innerAddMemberButton" @click="sendInvite">
											Send Invite
										</button>
										<div class="innerAddMemberButton" id="cancelButton"
											@click="toggleAddMemberModal">
											Cancel
										</div>
									</div>
									<div
										style="height: 18px; display: flex; align-items: center; margin-left: 3px; position: relative;">
										<div class="errorText">{{ errorText }}</div>
										<div class="successText">{{ successText }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="header">
					<div class="header1">
						<div class="teamName">Team Name</div>
						<div style="height: 21px; width: 209px; display: flex; position: relative;">
							<img src="../assets/lockicon.svg" alt="lock icon">
							<div class="onlyInvitedMembers">Only invited members can access</div>
						</div>
						<!-- <div class="backToDashboardContainer" @mouseenter="backToDashboardHover = true" @click="goBackToDashboard">
          <div class="relativeDiv textContainer" id="textContainer">
            <img src="../assets/purplebackarrow.svg" alt="back icon"
              style="height: 10px; position: absolute; top: 4.5px; cursor: pointer;" id="backToDashboardIcon">
            <div class="backToDashboard">Back To Dashboard</div>
          </div>
        </div> -->
					</div>
					<div class="header2">
						<div style="width: 647px; height: 40px; display: flex; position: relative;">
							<!-- <div :class="{ manageTeamSearchContainer: true, searchContainerActive: searchActive }">
								<img src="../assets/searchicon.svg" alt="search icon" style="margin-left: 16px;">
								<input type="text" class="searchForTeamMemberInput" spellcheck="false"
									placeholder="Search for a team member" @focus="searchActive = true"
									@blur="searchActive = false">
								
							</div> -->
							<div class="addMemberButton" @click="toggleAddMemberModal">
									<img src="../assets/addicon.svg" alt="add icon" style="margin-left: 16px;">
									<div class="addMemberButtonText">Add member</div>
								</div>
						</div>
						<div class="seatsUsedTextContainer">
							<div class="seatsUsedText">{{ licenseInfo.licenses_used }} of {{ licenseInfo.licenses }}
								seats
								used -</div>&nbsp;
							<div class="seatsUsedDescription">To add more seats to your account, please send us an email
								to</div>&nbsp;

							<a class="seatsUsedEmail"
								href="mailto:support@scenik.io?subject=License Support">support@scenik.io</a>
						</div>
					</div>
					<!-- <div class="header3">
        <div class="tabSelectorContainer">
          <div :class="{ selectorText: true, selectorActive: membersActive }" style="width: 80px;" @click="togglePage('members')">Members</div>
          <div :class="{ selectorText: true, selectorActive: guestsActive }" style="position: absolute; right: 0; width: 57px; text-align: right;"
            @click="togglePage('guests')">
            Guests
          </div>
        </div>
      </div> -->
				</div>
				<div class="body" v-show="membersActive">
					<div class="dataRow" id="firstRow">
						<div class="dataColumn" style="width: 382px; align-items: start;" id="columnName">
							<span class="dataText" style="margin-left: 72px; color: #858585;">Name</span>
						</div>
						<div class="dataColumn" style="width: 198px; align-items: start;" id="columnRole">
							<span class="dataText" style="color: #858585;">Role</span>
						</div>
						<div class="dataColumn" style="width: 167px; align-items: start;" id="columnLastSeen">
							<span class="dataText" style="color: #858585;">Last Seen</span>
						</div>
						<div class="dataColumn" style="width: 138px align-items: start;" id="columnLast">
						</div>
					</div>
					<div v-for="data, i in adminTeam" :key="i" class="dataRow">
						<div class="dataColumn" style="width: 382px;">
							<img :src="data.picture" alt="User's profile picture" class="profileImage"
								style="margin-left: 16px;" />
							<div
								style="width: 310px; display: flex; height: 30px; flex-direction: column; position: absolute; left: 72px;">
								<div class="dataText">{{ data.username }}</div>
								<div class="dataText"
									style="color: #858585; font-family: GT-America-Light; font-weight: 300; margin-top: 2px;">
									{{ data.email }}</div>
							</div>
						</div>
						<div class="dataColumn" style="width: 198px;">
							<span class="dataText">{{ data.primary_team == false ? "Guest" : (data.admin_flag == true ?
				"Super Admin" : "Admin") }}</span>
						</div>
						<div class="dataColumn" style="width: 167px;">
							<span class="dataText">{{ getDaysAgo(data.last_seen) }}</span>
						</div>
						<div class="dataColumn" style="width: 138px;"
							v-show="data.admin_flag != true && data.unique_user_id != user.sub">
							<button class="removeButton" @click="removeUser(data.unique_user_id)">Remove</button>
						</div>
						<div class="dataColumn" v-if="!data.email_verified" style="width: 167px;">
							<span class="inviteText">Invite Sent</span>
						</div>
					</div>
				</div>
				<div class="body" v-show="guestsActive">
					<div class="teamName" style="padding-bottom:20px;">Guest Users</div>
					<div style="display: flex; position: relative;padding-bottom:30px;">
						<div
							style="font-family: GT-America-Light; font-weight: 400; font-style:normal;font-size:16px;line-height:22px;">
							Guest users are users that are not
							on your team but are using a
							license because a project is being shared with them. You can remove thteir access to your
							projects below.</div>
					</div>

					<div v-for="data, i in guestTeam" :key="i" class="dataRow">
						<div class="dataColumn" style="width: 382px;">
							<img :src="data.picture" alt="User's profile picture" class="profileImage"
								style="margin-left: 16px;" />
							<div
								style="width: 310px; display: flex; height: 30px; flex-direction: column; position: absolute; left: 72px;">
								<div class="dataText">{{ data.username }}</div>
								<div class="dataText"
									style="color: #858585; font-family: GT-America-Light; font-weight: 300; margin-top: 2px;">
									{{ data.email }}</div>
							</div>
						</div>
						<div class="dataColumn" style="width: 198px;">
							<span class="dataText">{{ data.primary_team == false ? "Guest" : (data.admin_flag == true ?
				"Super Admin" : "Admin") }}</span>
						</div>
						<div class="dataColumn" style="width: 167px;">
							<span class="dataText">{{ getDaysAgo(data.last_seen) }}</span>
						</div>
						<div class="dataColumn" style="width: 138px;"
							v-show="data.admin_flag != true && data.unique_user_id != user.sub">
							<button class="removeButton" @click="removeUser(data.unique_user_id)">Remove</button>
						</div>
						<div class="dataColumn" v-if="!data.email_verified" style="width: 167px;">
							<span class="inviteText">Invite Sent</span>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script setup>

import {
	inject, onMounted, ref, computed, watch,
} from 'vue';
import PageHeader from '../components/PageHeader.vue';
import ProfileSettings from '../components/ProfileSettings.vue';
import NavBar from '../components/NavBar.vue';
import { useAuth0 } from '@auth0/auth0-vue';
import axios from 'axios';

const { user, getAccessTokenSilently } = useAuth0();

const store = inject('store');

// const goBackToDashboard = () => {
//   store.methods.activateManageTeam(false);
// };

//const activeRow = ref(null);
// const updateActiveRow = (num) => {
//   if (num === activeRow.value) activeRow.value = null;
//   else activeRow.value = num;
// };

let teamData = ref([]);
let licenseInfo = ref({});

const adminTeam = ref([]);
const guestTeam = ref([]);

const addMemberModalVisible = ref(false);
const heightOfWindow = ref(0);
const bodyStyle = computed(() => {
	const height = heightOfWindow.value - 60;
	return { height: `${height}px` };
});
watch(store.state, (data) => {
	if (data.profileSettingsEnabled) addMemberModalVisible.value = false;
});
const nameActive = ref(false);
const emailActive = ref(false);
const errorText = ref('');
const successText = ref('');
const name = ref('');
const email = ref('');
const radioSelection = ref('member');
// const selectRadio = (str) => {
//   radioSelection.value = str;
// };
const toggleAddMemberModal = () => {
	addMemberModalVisible.value = !addMemberModalVisible.value;
	radioSelection.value = 'member';
	name.value = '';
	email.value = '';
	successText.value = '';
	errorText.value = '';
};

//const searchActive = ref(false);
const membersActive = ref(true);
let guestsActive = ref(false);
/*const togglePage = (str) => {
  if (str === 'members') {
	membersActive.value = true;
	guestsActive.value = false;
	activeRow.value = null;
  }
  if (str === 'guests') {
	membersActive.value = false;
	guestsActive.value = true;
	activeRow.value = null;
  }
  
};*/

const sendInvite = () => {
	errorText.value = '';
	successText.value = '';
	axios.post(`${store.state.baseApiStr}/manageteam`, { operation: "add", args: { name: name.value, email: email.value } },
		{
			headers: {
				authorization: `Bearer ${token}`,
			},
		}).then((response) => {
			if (response.data.error) {
				successText.value = '';
				errorText.value = response.data.msg;
			}
			else {
				successText.value = response.data.msg;
				errorText.value = '';
				store.methods.apiCallGetUsersForTeam(user, token).then((message) => {
					teamData.value = message.data.team;
					licenseInfo.value = message.data.license;

					adminTeam.value = [];
					guestTeam.value = [];

					for (const user of teamData.value) {
						if (user.primary_team) {
							adminTeam.value.push(user);
						} else {
							guestTeam.value.push(user);
						}
					}

					if (guestTeam.value.length) {
						guestsActive = true;
					}

				});
			}
		}).catch((error) => {
			console.log('ManageTeam response:', error);
		});
};

function getDaysAgo(lastLaunch) {
	if (lastLaunch) {
		var now = new Date();
		var lastLaunchTime = new Date(lastLaunch);
		var diff = Math.abs(now - lastLaunchTime);  // difference in milliseconds
		let daysAgo = Math.trunc(diff / 86400000);
		if (daysAgo == 0) {
			let hoursAgo = Math.trunc(diff / 3600000);
			if (hoursAgo == 0) {
				let minutesAgo = Math.trunc(diff / 60000);
				if (minutesAgo == 1) {
					return "1 minute ago";
				} else if (minutesAgo == 0) {
					return "Less than 1 minute ago";
				} else {
					return minutesAgo + " minutes ago";
				}
			} else {
				return Math.trunc(diff / 3600000) + " hours ago";
			}
		} else if (daysAgo == 1) {
			return "1 day ago";
		} else {
			return daysAgo + " days ago";
		}
	} else {
		return "Never seen";
	}
}

let token;

onMounted(async () => {

	token = await getAccessTokenSilently();
	store.methods.apiCallGetUsersForTeam(user, token).then((message) => {
		teamData.value = message.data.team;
		licenseInfo.value = message.data.license;

		adminTeam.value = [];
		guestTeam.value = [];

		for (const user of teamData.value) {
			if (user.primary_team) {
				adminTeam.value.push(user);
			} else {
				guestTeam.value.push(user);
			}
		}

		if (guestTeam.value.length) {
			guestsActive = true;
		}
	});

	//store.methods.activateManageTeam(true);

	// store.methods.injectAuth().then((response) => {
	//   if (response === 'success') {
	//     // console.log('successful auth injection');
	//     store.methods.apiCallAllProjects().then((message) => {
	//       store.methods.updateProjects(JSON.parse(message.data.response));
	//       // console.log('api message from projects: ', store.state.allProjects);
	//     });
	//   }
	// });

	// dummy data for manage team page

});


const removeUser = (userId) => {
	axios.post(`${store.state.baseApiStr}/manageteam`, { operation: "remove", args: { userId: userId } },
		{
			headers: {
				authorization: `Bearer ${token}`,
			},
		}).then((response) => {
			console.log('ManageTeam response:', response.data);
			if (response.data.error) {
				console.log(response.data.error);
			}
			else {
				store.methods.apiCallGetUsersForTeam(user, token).then((message) => {
					console.log(message.data);
					teamData.value = message.data.team;
					licenseInfo.value = message.data.license;

					adminTeam.value = [];
					guestTeam.value = [];

					for (const user of teamData.value) {
						if (user.primary_team) {
							adminTeam.value.push(user);
						} else {
							guestTeam.value.push(user);
						}
					}

					if (guestTeam.value.length) {
						guestsActive = true;
					}
				});
			}
		}).catch((error) => {
			console.log('ManageTeam response:', error);
		});
};

</script>

<style scoped>
.backToDashboard {
	color: #009DF8;
	font-family: 'GT-America-Bold';
	cursor: pointer;
	border: none;
	background: unset;
	margin-left: 10px;
	font-size: 15px;

}

.backToDashboard:hover {
	opacity: 0.9;
	cursor: pointer;
	text-decoration: underline;
}

.backToDashboard:hover:active {
	/* border: 0.5px solid #FFF; */
	opacity: 1.1;
}

.inviteText {
	color: #009DF8;
	font-family: 'GT-America-Medium';
	border: none;
	background: unset;
	font-size: 13px;
}

.removeButton {
	color: #ff3232;
	font-family: 'GT-America-Bold';
	cursor: pointer;
	border: none;
	background: unset;
}

.removeButton:hover {
	opacity: 0.9;
	cursor: pointer;
}

.removeButton:hover:active {
	/* border: 0.5px solid #FFF; */
	opacity: 1.1;
}

.errorText {
	height: 12px;
	color: #FF3E00;
	font-family: 'GT-America-Bold';
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.24px;
	line-height: 15px;
}

.successText {
	height: 12px;
	color: #009DF8;
	font-family: 'GT-America-Bold';
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.24px;
	line-height: 15px;
}

#cancelButton {
	margin-left: 88px;
	background: transparent;
	color: #009DF8;
}

#cancelButton:hover {
	background: rgba(97, 60, 151, 0.05);
}

#cancelButton:hover:active {
	background: rgba(97, 60, 151, 0.07);
}

.innerAddMemberButton {
	box-sizing: border-box;
	height: 40px;
	width: 150px;
	border-radius: 10px;
	border: 1px solid #009DF8;
	color: #FFF;
	font-family: 'GT-America-Medium';
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #009DF8;
	cursor: pointer;
	user-select: none;
}

.innerAddMemberButton:hover {
	background: #009DF8;
}

.innerAddMemberButton:hover:active {
	background: #009DF8;
}

.radio {
	height: 9px;
	width: 9px;
	border-radius: 60px;
	background-color: #009DF8;
}

.radioOutline {
	box-sizing: border-box;
	height: 14px;
	width: 14px;
	border-radius: 60px;
	border: 1px solid #009DF8;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4px;
}

.radioText {
	height: 14px;
	color: #1C1C1C;
	font-family: 'GT-America-Light';
	font-size: 14px;
	font-weight: 300;
	line-height: 17px;
	padding-left: 9px;
	user-select: none;
	cursor: default;
}

.nameCounterText {
	height: 15px;
	color: #1C1C1C;
	font-family: 'GT-America-Light';
	font-size: 12px;
	font-weight: 300;
	letter-spacing: 0.24px;
	line-height: 15px;
	text-align: right;
	right: 2px;
	position: absolute;
	margin-top: 3.5px;
}

.subSectionText {
	height: 12px;
	color: #1C1C1C;
	font-family: 'GT-America-Medium';
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
}

.flexDiv {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.addNewMemberTitle {
	height: 16px;
	color: #1C1C1C;
	font-family: 'GT-America-Medium';
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	position: absolute;
	left: 18px;
}

.addMemberModal {
	position: absolute;
	top: 105px;
	height: 320px;
	width: 427px;
	right: -2px;
	box-shadow: 0 0 10px 0 rgba(28, 28, 28, 0.5);
	border-radius: 10px;
	background: #FFF;
}

.rowOpenContainer {
	width: 100%;
}

.arrowContainer:hover {
	background: #FFF;
}

.arrowContainer {
	height: 26px;
	width: 26px;
	border-radius: 40px;
	margin-right: 7px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 21px;
}

.guestArrowIcon {
	transform: rotate(-90deg);
	margin-left: 1px;
	margin-top: 1px;
}

.guestArrowIconActive {
	transform: rotate(0deg);
	margin-left: 1px;
	margin-top: 1px;
}

.projectsArrowIcon {
	transform: rotate(-90deg);
}

.projectText {
	height: 17px;
	color: #009DF8;
	font-family: 'GT-America-Regular';
	font-size: 14px;
	line-height: 17px;
}

.selectorText {
	height: 34.5px;
	color: #009DF8;
	font-family: 'GT-America-Medium';
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	cursor: pointer;
}

.selectorActive {
	border-bottom: 2px solid #009DF8;
}

.tabSelectorContainer {
	height: 34.5px;
	width: 154px;
	border-bottom: 1px solid rgba(85, 155, 245, 0.4);
	display: flex;
}

.dataText {
	height: 13px;
	color: #1C1C1C;
	font-family: 'GT-America-Medium';
	font-size: 13px;
	font-weight: 400;
	line-height: 15px;
}

.dataColumn {
	height: 100%;
	display: flex;
	position: relative;
	align-items: center;
}

.dataRow {
	height: 60px;
	display: flex;
	width: 100%;
	border-top: 1px #F6F6F6 solid;
	cursor: default;
	position: relative;
}

.dataRowActive {
	height: 60px;
	display: flex;
	width: 100%;
	border-top: 1px #F6F6F6 solid;
	background: #F6F6F6;
	cursor: default;
	position: relative
}

.dataRowOpen {
	height: 60px;
	display: flex;
	width: 100%;
	border-top: 1px #E6E6E6 solid;
	background: #F6F6F6;
	cursor: default;
	position: relative
}

.dataRow:hover {
	background: #F6F6F6;
}

.dataRowActive:hover,
.dataRowOpen:hover {
	background: #E6E6E6;
}

#firstRow:hover {
	background: transparent;
}

#firstRow {
	border-top: none;
	height: 43px;
}

.seatsUsedEmail {
	height: 12px;
	color: #009DF8;
	font-family: 'GT-America-Medium';
	font-size: 14px;
	font-weight: 400;
	line-height: 15px;
}

.seatsUsedDescription {
	height: 12px;
	color: #1C1C1C;
	font-family: 'GT-America-Light';
	font-size: 12px;
	font-weight: 300;
	line-height: 15px;
}

.seatsUsedText {
	height: 18px;
	color: #009DF8;
	font-family: 'GT-America-Medium';
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.seatsUsedTextContainer {
	height: 21px;
	width: 660px;
	margin-top: 23px;
	display: flex;
	align-items: center;
}

.addMemberButtonText {
	height: 14.48px;
	color: #FFF;
	font-family: 'GT-America-Medium';
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	margin-left: 10px;
	user-select: none;
}

.addMemberButton {
	height: 40px;
	width: 150px;
	border-radius: 10px;
	background-color: #009DF8;
	position: absolute;
	right: 0px;
	display: flex;
	align-items: center;
	cursor: pointer;
	z-index: 6;
}

.addMemberButton:hover {
	background-color: #009DF8;
}

.addMemberButton:hover:active {
	background-color: #009DF8;
}

.searchForTeamMemberInput {
	background: transparent;
	border: none;
	outline: none;
	width: 100%;
	padding-left: 10.5px;
	color: #1C1C1C;
	font-family: 'GT-America-Light';
	font-size: 14px;
	font-weight: 300;
	line-height: 17px;
}

.manageTeamSearchContainer {
	height: 40px;
	width: 477px;
	border-radius: 10px;
	background-color: #F6F6F6;
	display: flex;
	align-items: center;
}

.searchContainerActive {
	box-shadow: inset 0 0 0 1px #009DF8;
}

.textContainer {
	cursor: pointer;
	display: flex;
}

.textContainer:hover {
	text-decoration: underline;
	cursor: pointer;
}

#textContainer:hover {
	text-decoration: underline;
	cursor: pointer;
}

.backToDashboardContainer {
	height: 17px;
	width: 155px;
	display: flex;
	position: absolute;
	top: 67px;
	z-index: 100;
}

.onlyInvitedMembers {
	height: 21px;
	width: 209px;
	color: #555;
	font-family: 'GT-America-Regular';
	font-size: 12px;
	font-weight: 300;
	line-height: 18px;
	position: absolute;
	left: 21px;
	top: 3px;
}

.teamName {
	height: 23px;
	width: 239px;
	color: #009DF8;
	font-family: 'GT-America-Bold';
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
}

.body {
	width: 1085px;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
}

.header1 {
	width: 259px;
	position: relative;
	display: flex;
	flex-direction: column;
}

.header2 {
	width: 672px;
	position: relative;
	display: flex;
	flex-direction: column;
}

.header3 {
	width: 154px;
	position: relative;
	display: flex;
	flex-direction: column;
}

.header {
	height: 83.5px;
	width: 1085px;
	margin-top: 40.5px;
	display: flex;
}
</style>
