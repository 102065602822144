<template>
  <div class="relativeDiv" style="display: flex; justify-content: center; align-items: center;">
    <div class="profileSettingsContainer">
      <div class="profileSettingsHeader">
        <span :class="{ profileSettingsTitle: true, profileSettingsTitleActive: profileSettingsActive }"
           @click="toggleBetweenSettings('profile')">Profile Settings</span>
        <span :class="{ profileSettingsTitle: true, profileSettingsTitleActive: changePasswordActive }"
          style="margin-left: 160px; width: 170px;" @click="toggleBetweenSettings('password')">Update Password</span>
        <img src="../assets/closeicon.svg" alt="close button" style="position: absolute; right: 28px; top: 36px;"
          @click="closeProfileSettings">
      </div>
      <img :src="user.picture" alt="user profile image" class="profileImage" @mouseover="showEditImageOverlay = true"
        v-show="!showEditImageOverlay && profileSettingsActive && !newImageBeforeSave">
      <img :src="imageURL" alt="user profile image" class="profileImage" style="object-fit: cover;" @mouseover="showEditImageOverlay = true"
        v-show="!showEditImageOverlay && profileSettingsActive && newImageBeforeSave">
      <input type="file" id="newProfileImageInput" v-show="false" accept="image/x-png,image/jpg,image/jpeg"
        @change="readFile"/>
      <div class="editProfileImage" @click="openNewImage"
        v-show="showEditImageOverlay && profileSettingsActive && newImageBeforeSave === false" :style="editProfileImageStyle"
        @mouseleave="showEditImageOverlay = false">
        <img src="../assets/editprofileimage.svg" alt="edit image icon">
      </div>
      <div class="editProfileImage" @click="openNewImage"
        v-show="showEditImageOverlay && profileSettingsActive && newImageBeforeSave" :style="editProfileImageStyleNew"
        @mouseleave="showEditImageOverlay = false">
        <img src="../assets/editprofileimage.svg" alt="edit image icon">
      </div>
      <div class="changeName" v-show="profileSettingsActive">
        <span class="sectionTitle">Name</span>
        <input type="text" class="textInput" spellcheck="false" v-model="userName" maxlength="25">
        <div class="nameCounterText">{{ userName.length }}/25</div>
        <span class="nameError" v-show="userName.length < 1">Name needs to be at least 1 character*</span>
      </div>
      <div class="changePassword" v-show="changePasswordActive">
        <span class="sectionTitle" style="margin-top: 13px;">Current Password</span>
        <input type="password" class="textInput" spellcheck="false" v-model="currentPassword" @focus="disableMessages">
        <div class="messageContainer">
          <span class="forgotPassword" @click="forgotPasswordApiCall">Forgot Password?</span>
          <span class="emailSent" v-show="forgotPasswordMessage">Sent email with instructions to update your password.</span>
          <span class="passwordChangeMessage" v-show="wrongPasswordMessage">Wrong Password*</span>
        </div>
        <span class="sectionTitle" style="margin-top: 19.5px;">New Password</span>
        <input type="password" class="textInput" spellcheck="false" v-model="newPassword" @focus="disableMessages">
        <div class="passwordDescriptionTextContainer">
          <span class="passwordDescriptionText">• Non-empty password required.</span>
          <span class="passwordDescriptionText" style="margin-left: 19.5px;">• Must have at least 8 characters in length.</span><br />
          <span class="passwordDescriptionText">• Lower case (a-z), upper case (A-Z) and numbers (0-9).</span>
          <!-- <span class="passwordDescriptionText" style="margin-left: 19px;">• Special characters (!@#$%^&*).</span> -->
        </div>
        <span class="sectionTitle" style="margin-top: 27px;">Confirm New Password</span>
        <input type="password" class="textInput" spellcheck="false" v-model="confirmNewPassword" @focus="disableMessages">
        <div class="messageContainer">
          <span class="passwordChangeMessage" v-show="passwordsDontMatchMessage">Passwords are not matching*</span>
          <span class="passwordChangeMessage" v-show="passwordsDifferentMessage">New password must be different than old*</span>
          <span class="passwordChangeMessage" v-show="passwordWeakMessage">Password too weak*</span>
          <span class="passwordChangeMessage" v-show="passwordsEmptyMessage">Must enter value for both password fields*</span>
          <span class="passwordChangeMessage" style="color: #009537;" v-show="passwordChangeSuccessMessage">Password successfully changed*</span>
        </div>
      </div>
    <div class="twoButtons">
      <div class="profileSettingsButton" @click="updatePassword" v-show="changePasswordActive"
        style="margin-top: 20px;">Update Password</div>
      <div class="profileSettingsButton" v-show="profileSettingsActive" @click="updateUserInfo">Save</div>
    </div>
    </div>
  </div>
</template>

<script setup>

import {
  inject, ref, watch, computed,
} from 'vue';
import axios from 'axios';

const store = inject('store');

import { useAuth0 } from '@auth0/auth0-vue';
const { user,getAccessTokenSilently } = useAuth0();


let token;
getAccessTokenSilently().then((data)=>{
  token = data;
});

const userName = ref(user.value.name);

const openNewImage = () => {
  document.getElementById('newProfileImageInput').click();
};

const imageFile = ref();
const imageURL = ref();
const newImageBeforeSave = ref(false);

const updateUserHttpCall = (lastUser,base64Image) => {
 
  axios.post(`${store.state.baseApiStr}/updateuser`, {image:base64Image },
  {
    headers: {
      authorization: `Bearer ${token}`,
      name: lastUser,
    },
  }).then((response) => {
    console.log('update auth user api method response:', response.data);
    user.value.name = lastUser;
  }).catch((error) => {
    console.log('update auth user api response:', error);
  });
};

const readFile = (e) => {
  // eslint-disable-next-line
  imageFile.value = e.target.files[0];
  imageURL.value = URL.createObjectURL(imageFile.value);
  newImageBeforeSave.value = true;
};

const updateUserAPICall = () => {
  let lastUser = userName.value;

  function getBase64(file) {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let base64 = reader.result.split(",")[1];
        updateUserHttpCall(lastUser,base64);
      };
          
      reader.onerror = (error) => {
        console.log("image upload error: ", error);
      };
    } catch (e) {
      return;
    }
    
  }

  if (imageFile.value)
    getBase64(imageFile.value);  
  else
    updateUserHttpCall(lastUser);
  
};

const forgotPasswordMessage = ref(false);
const passwordsDontMatchMessage = ref(false);
const wrongPasswordMessage = ref(false);
const passwordChangeSuccessMessage = ref(false);
const passwordWeakMessage = ref(false);
const passwordsEmptyMessage = ref(false);
const passwordsDifferentMessage = ref(false);
const disableMessages = () => {
  passwordsDontMatchMessage.value = false;
  forgotPasswordMessage.value = false;
  wrongPasswordMessage.value = false;
  passwordChangeSuccessMessage.value = false;
  passwordWeakMessage.value = false;
  passwordsEmptyMessage.value = false;
  passwordsDifferentMessage.value = false;
};

const profileSettingsActive = ref(true);
const changePasswordActive = ref(false);
const toggleBetweenSettings = (type) => {
  disableMessages();
  if (type === 'profile') {
    profileSettingsActive.value = true;
    changePasswordActive.value = false;
  }
  if (type === 'password') {
    changePasswordActive.value = true;
    profileSettingsActive.value = false;
  }
};

const closeProfileSettings = () => {
  store.methods.enableProfileSettings(false);
  newImageBeforeSave.value = false;
  imageFile.value = ref();
  imageURL.value = ref();
  userName.value = user.value.name;
  disableMessages();
  toggleBetweenSettings('profile');
};

const currentPassword = ref('');
const newPassword = ref('');
const confirmNewPassword = ref('');
const pictureBase64 = ref('');

watch(store.state, (data) => {
  if (!data.profileSettingsEnabled) {
    userName.value = user.value.name;
    currentPassword.value = '';
    newPassword.value = '';
    confirmNewPassword.value = '';
    forgotPasswordMessage.value = false;
    pictureBase64.value = '';
    passwordsDontMatchMessage.value = false;
    wrongPasswordMessage.value = false;
    passwordWeakMessage.value = false;
    passwordsDifferentMessage.value = false;
  }
});

const showEditImageOverlay = ref(false);
// eslint-disable-next-line
const editProfileImageStyle = computed(() => {
  return {
    backgroundColor: 'rgba(246,246,246,0.6)',
    backgroundImage: `url(${user.value.picture})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    objectFit: 'cover',
    opacity: '0.3',
  };
});
// eslint-disable-next-line
const editProfileImageStyleNew = computed(() => {
  return {
    backgroundColor: 'rgba(246,246,246,0.6)',
    backgroundImage: `url(${imageURL.value})`,
    backgroundSize: 'cover',
    objectFit: 'cover',
    backgroundPosition: 'center',
    opacity: '0.3',
  };
});

const apiMethod = () => {
  updateUserAPICall();
};
const updateUserInfo = () => {
  if (userName.value !== user.value.name && newImageBeforeSave.value) {
    if (userName.value.length > 0 && userName.value.length < 26) apiMethod();
    store.methods.enableNewProfileImage(true);
    //store.methods.ProfileImage(false);
    store.methods.updateNewProfileImageURL(imageURL.value);
  } else if (userName.value === user.value.name && newImageBeforeSave.value) {
    apiMethod();
    store.methods.enableNewProfileImage(true);
    store.methods.updateNewProfileImageURL(imageURL.value);
  } else if (userName.value !== user.value.name && !newImageBeforeSave.value) {
    if (userName.value.length > 0 && userName.value.length < 51) apiMethod();
  }
  if (userName.value.length > 0 && userName.value.length < 51) store.methods.enableProfileSettings(false);
};

const forgotPasswordApiCall = () => {
  axios.get(`${store.state.baseApiStr}/lostpasswordemail`, {
    headers: {
      authorization: `Bearer ${store.state.accessToken}`,
      email: user.value.email,
    },
  }).then((res) => {
    console.log('api call forgot password response:', res.data.returnResponse);
    if (res.data.returnResponse === 'password reset email sent') {
      forgotPasswordMessage.value = true;
    }
  }).catch((err) => {
    console.log('api call forgot password', err);
  });
};
const apiUpdatePassword = () => {
  axios.post(`${store.state.baseApiStr}/changeuserpassword`, {}, {
    headers: {
      authorization: `Bearer ${store.state.accessToken}`,
      currentpassword: currentPassword.value,
      email: user.value.email,
      newpassword: newPassword.value,
    },
  }).then((res) => {
    console.log('api call update password:', res.data.returnResponse);
    if (res.data.returnResponse.includes('Wrong email or password')) wrongPasswordMessage.value = true;
    if (res.data.returnResponse.includes('Password is too weak')) passwordWeakMessage.value = true;
    if (res.data.returnResponse.includes('change password success')) {
      passwordChangeSuccessMessage.value = true;
      setTimeout(() => {
        this.$router.go();
      }, 2000);
    }
  }).catch((err) => {
    console.log('api call update password', err);
  });
};
const updatePassword = () => {
  if (newPassword.value.length < 1 || confirmNewPassword.value.length < 1 || currentPassword.value.length < 1) {
    passwordsEmptyMessage.value = true;
    return;
  }
  if (newPassword.value === currentPassword.value) {
    passwordsDifferentMessage.value = true;
    return;
  }
  if (newPassword.value === confirmNewPassword.value) {
    apiUpdatePassword();
  } else {
    passwordsDontMatchMessage.value = true;
  }
};

</script>

<style scoped>

.passwordDescriptionTextContainer {
  margin-top: 19px;
}

.passwordDescriptionText {
  height: 17px;
  width: 198.5px;
  color: #1C1C1C;
  font-family: 'GT-America-Regular';
  font-size: 12px;
  line-height: 15px;
}

.nameError {
  height: 18px;
  color: #FF3E00;
  font-family: 'GT-America-Bold';
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.24px;
  line-height: 15px;
  position: absolute;
  left: 1px;
  top: 79px;
}

.nameCounterText {
  height: 15px;
  color: #1C1C1C;
  font-family: 'GT-America-Light';
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.24px;
  line-height: 15px;
  text-align: right;
  right: 3px;
  position: absolute;
  top: 79px;
}

.profileSettingsButton {
  width: 261px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #009DF8;
  color: #FFF;
  font-family: 'GT-America-Medium';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  cursor: pointer;
}

.profileSettingsButton:hover {
  opacity: 0.9;
}

.twoButtons {
  position: absolute;
  width: 545px;
  height: 40px;
  display: flex;
  justify-content: center;
  bottom: 63px;
}

.editProfileImage {
  z-index: 2;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin-top: 81.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.messageContainer {
  width: 100%;
  height: 18px;
  margin-top: 10.5px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.forgotPassword {
  color: #009DF8;
  font-family: 'GT-America-Bold';
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.24px;
  line-height: 15px;
  cursor: pointer;
}

.passwordChangeMessage {
  color: #FF3E00;
  font-family: 'GT-America-Bold';
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.24px;
  line-height: 15px;
  cursor: default;
  right: 0;
  position: absolute;
  text-align: right;
}

.emailSent {
  height: 12px;
  color: #1C1C1C;
  font-family: 'GT-America-Light';
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.24px;
  line-height: 15px;
  cursor: default;
  right: 0;
  position: absolute;
  text-align: right;
}

.changePassword {
  height: 371px;
  width: 545.5px;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.textInput {
  height: 40px;
  width: 545px;
  border-radius: 10px;
  background-color: #F6F6F6;
  border: none;
  padding-left: 14px;
  color: #1C1C1C;
  font-family: 'GT-America-Regular';
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  margin-top: 14px;
}

.textInput:focus {
  outline: none;
  box-shadow: inset 0 0 0 1px #009DF8;
}

.sectionTitle {
  height: 17px;
  color: #1C1C1C;
  font-family: 'GT-America-Regular';
  font-size: 14px;
  line-height: 17px;
  cursor: default;
}

.profileImage {
  margin-top: 81.5px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.profileSettingsContainer {
  height: 574px;
  width: 600px;
  border-radius: 10px;
  background-color: #FFF;
  box-shadow: 0 0 10px 0 rgba(8, 28, 28, 0.12);
  z-index: 8;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.profileSettingsHeader {
  height: 66px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(85,155, 245, 0.4);
}

.profileSettingsTitle {
  height: 36px;
  color: #009DF8;
  font-family: 'GT-America-Medium';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  position: absolute;
  top: 30px;
  left: 21px;
  width: 143px;
  cursor: default;
}

.profileSettingsTitle:hover {
  cursor: pointer;
}

.profileSettingsTitleActive {
  border-bottom: 2px solid #009DF8;
}

.changeName {
  margin-top: 18.5px;
  height: 95px;
  width: 545px;
  display: flex;
  flex-direction: column;
  position: relative;
}

</style>
