<template>
  <div class="loadingContainer">
    <div class="vertFlex" v-if="type === 'search'">
      <img src="../assets/no-results.png" alt="link not found" style="height: 392px; width: 644px;">
      <div class="titleText">
        No results found
      </div>
      <div class="descriptionText">
        Try adjusting your search to find what you’re looking for.<br/>
      </div>
    </div>
    <div class="vertFlex" v-if="type === 'searchHome'">
      <img src="../assets/no-results.png" alt="link not found" style="height: 212px; width: 345px;">
      <div class="titleText" style="margin-top: 20px">
        No results found
      </div>
      <div class="descriptionText">
        Try adjusting your search to find what you’re looking for.<br/>
      </div>
    </div>
    <div class="vertFlex" v-if="type === 'noProjects'">
      <img src="../assets/noProjectsFound.png" alt="link not found" style="height: 392px; width: 644px;">
      <div class="titleText">
        Let's Get Started
      </div>
      <div class="descriptionText">
        Start a new project or bring over an existing one by clicking the "New<br/>Project button on the upper right corner of the page.<br/>
      </div>
    </div>
    <div class="vertFlex" v-if="type === 'noProjectsHome'">
      <img src="../assets/noProjectsFound.png" alt="link not found" style="height: 212px; width: 345px;">
      <div class="titleText" style="margin-top: 20px;">
        Let's Get Started
      </div>
      <div class="descriptionText">
        Start a new project or bring over an existing one by clicking the "New<br/>Project button on the upper right corner of the page.<br/>
      </div>
    </div>
  </div>
</template>

<script>

import {
  inject,
} from 'vue';

export default {
  name: 'PageNotFound',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = inject('store');
    return {
      store,
    };
  },
};
</script>

<style scoped>

.descriptionText {
  margin-top: 14px;
  color: #1D1D1F;
  font-family: 'GT-America-Regular';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.13px;
  line-height: 20px;
  text-align: center;
}

.titleText {
  margin-top: 45px;
  color: #009DF8;
  font-family: 'GT-America-Regular';
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
}

.loadingButton {
  width: 170px;
}

.loadingContainer
{
  width:100%;
  height:100;
}

.vertFlex
{
  display:flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingText {
  margin-top:30px;
  color: #1D1D1F;
  font-family: "GT-America-Light";
  font-size: 16px;
  line-height: 20px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
