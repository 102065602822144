<template>
  <div class="loadingContainer">
    <img src="../assets/logo@2x.png" style="height: 60px; width: 60px; position: absolute;" alt="logo"/>
    <div class="vertFlex">
      <img src="../assets/link-error.png" alt="link not found" style="height: 392px; width: 644px;">
      <div class="titleText">
        There was a problem with your link.
      </div>
      <div class="descriptionText">
        Looks like the link you followed has expired or wasn't copied correctly.<br/>
        Please check and try again, or ask one of your teammates to send another.
      </div>
      <router-link to="/projects" class="dashboardButton contentButton loadingButton"
        style="position: relative; margin-top: 45px;">
        <div class="buttonText">Go to Dashboard</div>
      </router-link>
      <!-- <h1 class="loadingText">{{store.state.renderSessionStatus}}</h1> -->
    </div>
  </div>
</template>

<script>

import {
  inject,
} from 'vue';

export default {
  name: 'PageNotFound',
  props: {},
  setup() {
    const store = inject('store');
    return {
      store,
    };
  },
};
</script>

<style scoped>

.descriptionText {
  margin-top: 14px;
  color: #1D1D1F;
  font-family: "GT-America-Regular";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.13px;
  line-height: 20px;
  text-align: center;
}

.titleText {
  margin-top: 45px;
  color: #009DF8;
  font-family: 'GT-America-Regular';
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
}

.loadingButton {
  width: 170px;
}

.loadingContainer
{
  width:100%;
  height:100;
}

.vertFlex
{
  display:flex;
  height:100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingText {
  margin-top:30px;
  color: #1D1D1F;
  font-family: "GT-America-Light";
  font-size: 16px;
  line-height: 20px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
