<template>
	<div ref="dropdown" class="dropdown">
		<span>
			<el-button class="dropdown-button" circle size="small" @click.stop="showDropdown">
				<img src="@/assets/icons/Sub_menu_Dots.svg" alt="Sub_menu_Dots">
			</el-button>
		</span>
		<ul class="dropdown__list hide">
			<slot />
		</ul>
	</div>
</template>

<script setup>
import { ref } from 'vue'

const dropdown = ref(null)
const showDropdown = () => {
	hideAllDropdowns()
	dropdown.value.querySelector('.dropdown__list').classList.remove('hide')
}

const hideAllDropdowns = () => {
	const dropdowns = document.querySelectorAll('.dropdown__list')
	dropdowns.forEach(item => item.classList.add('hide'))
}

window.addEventListener('click', () => {
	hideAllDropdowns()
})
</script>

<style lang="scss">
.dropdown {
	&__list {
		padding: 0;
		position: absolute;
		top: -10px;
		right: -10px;
		background-color: #fff;
		border-radius: 10px;
		margin: 0;
		width: max-content;
		text-align: center;

		li {
			font-size: 14px;
			font-family: 'GT-America-Medium';
			color: var(--el-color-primary);
			padding: 10px 20px;
			list-style: none;
			transition: all 0.3s ease;
			cursor: pointer;

			&:hover {
				background-color: rgba(96, 58, 150, 0.1);
			}
		}
	}

	.hide {
		display: none;
	}
}
</style>
