<template>
  <div class="newProjectContainer">
      <div class="settingsContainer">
        <div class="settingsContainerItem">
          <div class="thumbnailContainer">
              <div v-if="!store.state.editingProject" class="sectionTitle">
                Add Thumbnail
              </div>
              <div v-else class="sectionTitle">Edit Thumbnail</div>
              <div v-if="typeof projectThumbnail === 'string' &&
                projectThumbnail.length > 0
                " class="thumbnailImgContainer">
                <div class="relativeDiv loadedThumbnailContainer">
                  <img class="loadedThumbnail" :src="projectThumbnail" />
                </div>
              </div>
              <div v-else class="thumbnailImgContainer">
                <div class="defaultThumbnail"></div>
              </div>
              <div class="dashboardButton settingsButton" style="bottom: 0" @click="activateImageUpload">
                <div class="buttonText">Upload Image</div>
                <input v-show="false" type="file" id="imageInput" accept="image/png, image/jpeg"
                  @change="imageUploaded($event)" />
              </div>
              <div style="display:flex;flex-direction: row;">
              <div class="settingsDescription">
                Upload at least a<b style="margin-left:5px">300px x 200px</b>
              </div>
              <div class="settingsDescription" style="margin-left:5px">
                PNG/JPG.
              </div>
            </div>
          </div>
          <div class="projectNameContainer">
              <div class="sectionTitle">Project Name</div>
              <div :class="{ nameTextBox: true, textBoxActive: nameTextBoxActive }">
                <div class="relativeDiv" style="display: flex; align-items: center">
                  <input class="searchInput" id="nameTextBox" spellcheck="false" v-model="projectNameStr"
                    @focus="nameTextBoxActive = true" @blur="nameTextBoxActive = false" style="margin-left:4px"/>
                  <div style="display: flex;">
                    <div class="copyIconSeperator"></div>
                    <img class="copyIcon" @click="copyTextToClipboard('name')" title="Copy text"
                      src="../assets/copyicon.svg" />
                  </div>
                </div>
              </div>
              <div class="settingsDescription" style="width: 100%;margin-top: -15px; ">
                  <div class="settingsDescription" v-show="nameTextCopied" style="color: #009DF8;">
                    Text copied!
                  </div>
              </div>
              <div v-show="store.state.editingProjectName" class="dashboardButton settingsButton"
                style="" @click="saveProject">
              <div class="buttonText">Save</div>
            </div>
          </div>
          <div v-show="!store.state.editingProjectName" class="dashboardButton settingsButton"
            style="opacity: 0.5; cursor: default">
            <div class="buttonText">Save</div>
          </div>
        </div>
      
        <div class="projectUploadContainer" style="margin-top:20px;margin-left:20px">
         
              <h4 class="description">
                <b class="instructionLink" style="f">Current File Uploaded:</b> {{ lastFileSizeUploadedFormatted !== null && lastFileSizeUploadedFormatted !== 0 && lastFileSizeUploadedFormatted !== '' && lastFileSizeUploadedFormatted !== '0 B' ? lastFileSizeUploadedFormatted : ''}} {{ lastFileDateUploaded !== '' ? lastFileDateUploaded : 'None'}}
              </h4>
              <WorldUpload width="100%" file-type="zip" @uploadFile="uploadFileSelected" :uploadstatus="progress" :uploading="uploadStarted"
                @fileRemoved="emits('fileRemoved')" />
        
        </div>
        <div class="packagingInstructions">
             <div class="instructionLink">Packaging Notes</div>
             <h4 class="instructions" style="margin-top:10px">
                Upload your scenes packaged .Zip file here with the Pixel
                Streaming plugin enabled.<br><a href="https://docs.unrealengine.com/5.3/en-US/getting-started-with-pixel-streaming-in-unreal-engine/" class="instructionLink">See Step #1 Here</a>
              </h4>
              <ul class="description">
                <li>Enable the <b>Pixelstreaming</b> plugin in your project settings</li>
                <li>Enable <b>Electra Movie Player</b> in plugins if using videos</li>
                <li>Turn Off <b>Lumen Hardware Ray Tracing</b> <br><img src="../assets/LumenHardwareRayOff.png" style="width:550px"></li>
                <li>Unreal Engine 5.1 has an issue that affects loading of scenes and may fail 25% of the time</li>
                <li>Unreal Engine 5.2/5.3 has an issue that impacts scene performance</li>
              </ul>
              
        </div>
        <div v-if="!store.state.editingProject" class="bottomContainer" ></div>
          <div v-else class="bottomContainer" style="margin-top:40px;margin-left:20px">
            <div class="bottomContainerItem">
                <div class="bottomContainerTitle">
                  <span v-show="store.state.editingProjectObj.visible">Archive</span>
                  <span v-show="!store.state.editingProjectObj.visible">Restore</span>
                  Project
                </div>
                <div class="bottomContainerSeperator"></div>
                <div v-show="store.state.editingProjectObj.visible" class="settingsDescription" style="
                    height: 59px;
                    margin-top:10px;
                    width: 300px;
                  ">
                  If you archive this project, nobody will be able to access
                  it.<br />
                </div>
                <div v-show="!store.state.editingProjectObj.visible" class="settingsDescription" style="
                    height: 59px;
                    width: 300px;
                  ">
                  Restore this project to be able<br />
                  to access it.<br />
                </div>
                <div class="dashboardButton settingsButton" style="width: 186px;"
                  @click="updateProject('archive')">
                  <div v-show="store.state.editingProjectObj.visible" class="buttonText">
                    Archive
                  </div>
                  <div v-show="!store.state.editingProjectObj.visible" class="buttonText">
                    Restore
                  </div>
              </div>
            </div>
            <div class="bottomContainerItem">
              <div class="relativeDiv rightContainer">
                <div class="bottomContainerTitle">Delete Project</div>
                <div class="bottomContainerSeperator"></div>
                <div class="settingsDescription" style="
                    height: 29px;
                    width: 100%;
                    margin-top:10px;
                  " id="deleteText">
                  Type "Delete" to Remove Project.
                </div>
                <div :class="{ textBox: true, textBoxActive: deleteTextBoxActive }" :style="styleTextBox">
                  <input class="searchInput" id="deleteInput" spellcheck="false" v-model="deleteStr"
                    style="width: 100%;margin-left:4px;" @focus="deleteTextBoxActive = true"
                    @blur="deleteTextBoxActive = false" />
                </div>
                <div class="dashboardButton settingsButton" style="width: 186px;margin-top:20px;margin-bottom:30px"
                  @click="updateProject('delete')">
                  <div class="buttonText">Delete</div>
                </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, computed, ref, onMounted, defineProps } from "vue";
import axios from "axios";
import WorldUpload from "./WorldUpload.vue";
import { useFileUpload } from "@/composables/fileUpload";
import useUtils from './utils.js'
import { useAuth0 } from '@auth0/auth0-vue';

const { uploadFile,progress,setAccessToken } = useFileUpload();

uploadFile.uploadStatus = (status) => {
  fileUploadStatus.value = status;
};

const store = inject("store");

const MULTIPART_CHUNK_SIZE = 104857600;

const nameTextBoxActive = ref(false);
const commandTextCopied = ref(false);
// eslint-disable-next-line
const nameTextCopied = ref(false);
const deleteTextBoxActive = ref(false);

const uploadStarted = ref(false);
const fileUploadStatus = ref(false);

// eslint-disable-next-line
const styleTextBox = computed(() => {
  if (deleteTextBoxActive.value) {
    return {
      border: "none",
      top: "135px",
      left: "10px",
      overflow: "hidden",
      backgrounColor: "#F6F6F6",
    };
  }
  if (!deleteTextBoxActive.value) {
    return {
      border: "0.5px solid #808080",
      top: "135px",
      left: "10px",
      backgrounColor: "#F6F6F6",
    };
  }
});

const deleteStr = computed({
  get() {
    return store.state.deleteStr;
  },
  set(str) {
    store.methods.setDeleteStr(str);
  },
});

// const uploadButtonClicked = () => {
//   document.getElementById('uploadFolderInput').click();
// };

const uploadFileSelected = (file) => {
  store.methods.updateUploadFile(file);
  //Start uploading
  updateWorldFile();

  //Notify control to show upload progress
  uploadStarted.value = true;
};

// const fileRemoved = () => {
//   functionsForEdit.value.add(removeWorldFile)
//   store.commit('worlds/removeUploadedFile')
// }

const saveProject = () => {
  if (!store.state.editingProject) {
    store.methods.addProject();
  } else if (store.state.importFromUnreal) {
    store.methods.updateProject(
      "update",
      store.state.editingProjectObj.unique_project_id,
      store.state.editingProjectObj.allProjectsIndex,
      !store.state.editingProjectObj.visible,
      false,
      !store.state.importFromUnreal,
      undefined,
      store.state.editingProjectName
    );
    store.methods.updatePage("projects");
  } else {
    store.methods.updateProject(
      "update",
      store.state.editingProjectObj.unique_project_id,
      store.state.editingProjectObj.allProjectsIndex,
      !store.state.editingProjectObj.visible,
      false,
      !store.state.importFromUnreal,
      // Project thumbnail needs to be in base64 for the API
      store.state.project_thumbnail,
      store.state.editingProjectName
    );
    store.methods.updatePage("projects");
  }
};
const projectThumbnail = computed(() => {
  if (store.state.importFromUnreal) {
    return `${store.state.project_thumbnail}`;
  }
  if (store.state.project_thumbnail.length > 0) {
    return `${store.state.project_thumbnail}`;
  }
  return "";
});
const activateImageUpload = () => {
  document.getElementById("imageInput").click();
};
const imageUploaded = (e) => {
  const localStore = store;
  function getBase64(file) {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // eslint-disable-next-line
        if (localStore.state.importFromUnreal)
          store.methods.setImportFromUnreal();
        store.methods.updateProjectThumbnailFromBase64(
          reader.result.split(",")[1]
        );
    };
    reader.onerror = (error) => {
      console.log("image upload error: ", error);
    };
    } catch (e) {
      return;
    }
    
  }

  const file = e.target.files[0];
  getBase64(file); // prints the base64 string
};
// const importFromUnreal = computed({
//   get() {
//     return store.state.importFromUnreal;
//   },
//   set() {
//     store.methods.setImportFromUnreal();
//   },
// });

const projectNameStr = computed({
  get() {
    return store.state.editingProjectName;
  },
  set(str) {
    store.methods.setProjectName(str);
  },
});
const copyTextToClipboard = (type) => {
  let el;
  if (type === "name") {
    el = document.getElementById("nameTextBox");
    nameTextCopied.value = true;
    setTimeout(() => {
      nameTextCopied.value = false;
    }, 1000);
  }
  if (type === "command") {
    el = document.getElementById("commandTextBox");
    commandTextCopied.value = true;
    setTimeout(() => {
      commandTextCopied.value = false;
    }, 1000);
  }
  el.focus();
  el.select();
  document.execCommand("copy");
};
//const searchFileStr = ref('');

const props = defineProps({
  widthOfFlexContainer: String,
  file: {
    type: Object,
  },
});

const widthOfFlexContainer = computed(
  () => `${parseInt(props.widthOfFlexContainer, Number) - 60}px`
);
const widthOfProjectNameContainer = computed(
  () => `${parseInt(props.widthOfFlexContainer, Number) - 548}px`
);
const widthOfProjectUploadContainer = computed(
  () => `${parseInt(props.widthOfFlexContainer, Number) - 102}px`
);
const widthOfSearchContainer = computed(
  () => `${parseInt(props.widthOfFlexContainer, Number) - 379}px`
);
const widthOfSearchInput = computed(
  () => `${parseInt(props.widthOfFlexContainer, Number) - 435.5}px`
);
const widthOfNameInput = computed(
  () => `${parseInt(props.widthOfFlexContainer, Number) - 613}px`
);
const deleteContainerWidth = ref(0);
const widthOfDeleteInput = computed(() => `${deleteContainerWidth.value}px`);

const updateProject = (type) => {
  const obj = store.state.editingProjectObj;
  if (type === "archive") {
    store.methods.updatePage("projects");
    if (obj.visible)
      store.methods.updateProject(
        "update",
        obj.unique_project_id,
        obj.allProjectsIndex,
        true
      );
    else if (!obj.visible)
      store.methods.updateProject(
        "update",
        obj.unique_project_id,
        obj.allProjectsIndex,
        false
      );
  }
  if (type === "delete") {
    if (store.state.deleteStr === "Delete") {
      store.methods.deleteProjectConfirm();
    }
    if (store.state.deleteStr !== "Delete") {
      const blinkRed = () => {
        const el = document.getElementById("deleteText");
        el.style.color = "red";
        setTimeout(() => {
          el.style.color = "#1C1C1C";
        }, 250);
      };
      blinkRed();
      setTimeout(blinkRed, 500);
    }
  }
};

//////////////////////////// NEW UPLOAD /////////////////////////////////
const updateWorldFile = async () => {
  if (store.state.uploadFile) {
    let uploadResults = await uploadFile(
      store.state.editingProjectObj.unique_project_id,
      store.state.uploadFile
    );
    lastFileDateUploaded.value = new Date(uploadResults.latest_rev).toLocaleString();
    lastFileSizeUploaded.value = uploadResults.file_size;
    uploadStarted.value = false;
  }
};

////////////////////////////NEW UPLOAD END////////////////////////////////////////////////////////////

const totalUploadSize = ref(0);

const updateTotalUploadSize = (num) => {
  totalUploadSize.value += parseInt(num, 10);
  // console.log('total upload size:', totalUploadSize.value);
};

const filesLoadedArr = new Map();


const { humanFileSize } = useUtils()

const lastFileDateUploaded = ref('');
const lastFileSizeUploaded = ref(0);
const lastFileSizeUploadedFormatted = computed(() => { let size = humanFileSize(lastFileSizeUploaded.value);
  if (size == "NaN undefined") {
    return '';
  }else
  return size;
})

const fileUploadProgress =
  (myFileId, partNum = -1) =>
    (progress) => {
      let bytesLoaded = filesLoadedArr.get(myFileId.webkitRelativePath);
      if (bytesLoaded === undefined) {
        bytesLoaded = 0;
        filesLoadedArr.set(myFileId.webkitRelativePath, 0);
      } else if (myFileId.multipart) {
        //  Track separatly and then combine into total
        /* eslint-disable */
        myFileId.url[partNum - 1].uploadedTotal = progress.loaded;
        let newTotal = 0;
        for (let j = 0; j < myFileId.url.length; j += 1) {
          if (myFileId.url[j].uploadedTotal)
            newTotal += myFileId.url[j].uploadedTotal;
        }
        filesLoadedArr.set(myFileId.webkitRelativePath, newTotal);
        updateTotalUploadSize(parseInt(newTotal, 10) - parseInt(bytesLoaded, 10));
      } else {
        filesLoadedArr.set(myFileId.webkitRelativePath, progress.loaded);
        updateTotalUploadSize(
          parseInt(progress.loaded, 10) - parseInt(bytesLoaded, 10)
        );
      }
    };
// const percentage = Math.floor((progress.loaded * 100) / progress.total);
// console.log('progress', progress.loaded);
// console.log(`${percentage}% ${myFileId.webkitRelativePath}`);

const uploadMultiPartFile = async (fileObj) => {
  console.log("Upload multipart: ", fileObj);
  let bytesRead = 0;
  let endIndex = 0;
  const promises = new Array();
  let partNum = 1;
  //  Parts number should work out to be the same because we are using the same multipart chunk size
  while (bytesRead < fileObj.size) {
    if (endIndex + MULTIPART_CHUNK_SIZE > fileObj.size) {
      endIndex = fileObj.size;
    } else {
      endIndex += MULTIPART_CHUNK_SIZE;
    }

    // TODO I don't think this returns in order so hash could be different each time
    const nextSlice = fileObj.slice(bytesRead, endIndex);
    if (nextSlice.size > 0) {
      /* eslint-disable */
      //const headerInfo = { 'Content-Type': fileObj.contentType, 'x-amz-meta-filehash': fileObj.fileHash.fileHash };
      const headerInfo = { "Content-Type": fileObj.contentType };
      //const headerInfo = { 'UploadId': fileObj.url.uploadid, 'PartNumber': partNum,'Content-Type': fileObj.type, 'x-amz-meta-filehash': fileObj.fileHash.fileHash };

      const options = {
        headers: headerInfo,
        onUploadProgress: fileUploadProgress(fileObj, partNum),
      };

      //console.log(JSON.stringify(fileObj.url));
      //console.log(partNum);
      //console.log(JSON.stringify(fileObj.url.urls[partNum - 1].url));

      function putFile(fileObj, partNum, nextSlice, options) {
        return new Promise((resolve, reject) => {
          axios
            .put(fileObj.url[partNum - 1].url, nextSlice, options)
            .then((result) => {
              // console.log(`Etag:${result.headers.etag} Partnum:${partNum} Fileobj:${fileObj.name}`);
              fileObj.url[partNum - 1].etag = result.headers.etag;
              resolve();
            });
        });
      }
      promises.push(putFile(fileObj, partNum, nextSlice, options));
    }

    bytesRead += MULTIPART_CHUNK_SIZE;
    partNum += 1;
  }

  await Promise.all(promises);
};

const getLastFileUploaded = async() =>
{
  if (store.state.editingProjectObj.unique_project_id == null)
  {
    return;
  }

  if (store.state.editingProjectObj.latest_rev)
  {
    lastFileDateUploaded.value = new Date(store.state.editingProjectObj.latest_rev).toLocaleString();
    lastFileSizeUploaded.value = store.state.editingProjectObj.file_size;
  }
  else
  {
    lastFileDateUploaded.value = "No File Uploaded";
    lastFileSizeUploaded.value = null;
  }
  
}

onMounted(async () => {
  const { getAccessTokenSilently } = useAuth0();
  setAccessToken(await getAccessTokenSilently());

  // const resize = new ResizeObserver((entries) => {
  //   entries.forEach((entry) => {
  //     const cr = entry.contentRect;
  //     deleteContainerWidth.value = cr.width - 31;
  //   });
  // });
  // // start observing for resize
  // if (store.state.allProjects.length > 0 && store.state.editingProject === true)
  //   resize.observe(document.querySelector(".rightContainer"));

    getLastFileUploaded();
    
  })
  </script>

<style scoped>
.dropdownItemText {
  height: 15px;
  color: #1c1c1c;
  font-family: "GT-America-Light";
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  text-align: left;
  margin-left: 12px;
}

.dropdownItem {
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownItem:hover {
  background: #f6f6f6;
}

.dropdownItemSelected {
  background: #f6f6f6;
}

.dropdownItem:hover:active {
  background: #e6e6e6;
}

.dropdownContainer {
  height: 161px;
  flex: 1 1 100%;
  width: 100%;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  z-index: 2;
  position: absolute;
  top: 1px;
}

.dropdownIcon {
  background-image: url("../assets/down-arrow.svg");
  position: relative;
  margin-right: 16px;
  cursor: pointer;
  width: 11px;
  height: 5px;
}

.selectVersion {
  flex: 1 1 100%;
  height: 40px;
  color: #1c1c1c;
  font-family: "GT-America-Regular";
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  text-align: left;
  background: transparent;
  margin-left: 12px;
  display: flex;
  align-items: center;
}

.selectButtonContainer {
  flex: 1 0 50%;
  display: flex;
}

.loadingText {
  height: 20px;
  color: #1c1c1c;
  font-family: "GT-America-Medium";
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 20px;
  align-self: center;
  margin-top: 39px;
}

.loadingDiv {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}

.loadedThumbnailContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loadedThumbnail {
  background-size: 113.58px 114.54px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

#nameTextBox {
  width: v-bind(widthOfNameInput);
  left: 4px;
}

.copyIconSeperator {
  height: 17px;
  width: 1px;
  border: 0.5px solid #808080;
  opacity: 0.6;
  position: relative;
  margin-right: 15px;
}

.copyIcon {
  position: relative;
  margin-right: 15px;
  margin-top: 1spx;
  cursor: pointer;
}

.searchFilesInput {
  width: v-bind(widthOfSearchInput);
  height: 40px;
}

.textBoxActive {
  box-shadow: inset 0 0 0 1px #009DF8;
}

.bottomContainerTitle {
  height: 33px;
  width: 295px;
  color: #009DF8;
  font-family: "GT-America-Regular";
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}

.bottomContainerSeperator {
  box-sizing: border-box;
  height: 1px;
  width: 378px;
  border: 0.5px solid #009DF8;
  opacity: 0.3;
}

.defaultThumbnail {
  background-image: url("../assets/thumbnailsettingsdefault.svg");
  background-size: 113.58px 114.54px;
  height: 114.54px;
  width: 113.58px;
  margin-top:10px;
}

.bottomContainerItem {
  flex: 0 0 25%;
}

.dragHereIcon {
  background-image: url("../assets/uploadicon.svg");
  height: 70px;
  width: 79px;
}

.dragHereContianer {
  height: 115px;
  width: 454px;
  position: relative;
  justify-content: center;
  display: flex;
}

.searchContainer {
  height: 40px;
  background: #fff;
  border-radius: 10px;
  min-width: 259px;
  width: v-bind(widthOfSearchContainer);
}


.dragAndFileContainer {
  background: #fff;
  width: v-bind(widthOfProjectUploadContainer);
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectUploadContainer {
  width: v-bind(widthOfProjectUploadContainer);
}

.projectNameContainer {
  width: v-bind(widthOfProjectNameContainer);
  display:flex;
  flex-direction: column;
  gap: 15px;
}

.textBox {
  background: #fff;
  border-radius: 10px;
  height: 40px;
  width: v-bind(widthOfDeleteInput);
  min-width: 259px;
}

.nameTextBox {
  background: #fff;
  border-radius: 10px;
  width: 100%;
  min-width: 259px;
  height: 40px;
  max-width:350px;
  margin-left:4px;
}

.checkbox {
  position: relative;
  margin-bottom: 20px;
  background: #fff;
  height: 15px;
  width: 15px;
}

.checkbox label {
  padding-left: 30px;
  display: block;
}

.checkbox input {
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.checkbox input:checked+.input-helper:before {
  border-color: #009DF8;
}

.checkbox .input-helper:before,
.checkbox .input-helper:after {
  position: absolute;
  content: "";
  transition: all 200ms;
}

.checkbox .input-helper:before {
  left: 0;
  border: 0.5px solid #009DF8;
}

.checkbox input {
  width: 15px;
  height: 15px;
}

.checkbox input:checked+.input-helper:before {
  background-color: #009DF8;
}

.checkbox input:checked+.input-helper:after {
  transform: scale(1);
  opacity: 1;
}

.checkbox .input-helper:before {
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  color: #fff;
}

.checkbox .input-helper:after {
  content: url("../assets/checkmark.svg");
  font-size: 13px;
  left: 2.5px;
  top: -3px;
  color: #fff;
  transform: scale(0.1);
  opacity: 0;
}

.boldDescription {
  font-weight: 900;
}

.settingsDescription {
  height: 20px;
  color: #1c1c1c;
  font-family: "GT-America-Regular";
  font-size: 14px;
  font-weight: 300;
}

.settingsButton {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}

.newProjectContainer {
  width: 100%;
  height: 100%;
  display:flex;
  flex-direction: column;
}

.settingsContainer {
  display:flex;
  flex-direction:column;
  background-color: #f6f6f6;
  width: v-bind(widthOfFlexContainer);
}

.bottomContainer {
  top: 525px;
  display: flex;
  flex-direction: row;
}

.thumbnailContainer {
  width: 422px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
}

.sectionTitle {
  top: 0;
  left: 0;
  height: 17px;
  color: #1c1c1c;
  font-family: "GT-America-Medium";
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}

.commandTitle {
  height: 17px;
  color: #1c1c1c;
  font-family: "GT-America-Medium";
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}

.thumbnailImgContainer {
  background: #1c1c1c;
  height: 138px;
  width: 207px;
  border-radius: 2px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.description {
  font-size: 14px;
  font-family: "GT-America-Regular";
  margin-top: 0;
  font-weight: normal;
}

.instructions {
  font-size: 16px;
  font-family: "GT-America-Medium";
  margin-top: 0;
  font-weight: normal;
}

.instructionLink {
  font-size: 18px;
  font-family: "GT-America-Bold";
  color:#009DF8;
  margin-top: 0;
  font-weight: normal;
}

.settingsContainerItem
{
  display:flex;
  flex-direction:row;
  margin-top:20px;
  margin-left:20px
}
li {
  margin: 5px 0;
}

.packagingInstructions
{
  padding:20px;
  margin-left:20px;
  margin-right:20px;
  margin-top:20px;
  background:rgb(225, 225, 225);
  border-radius: 10px;
}
</style>
