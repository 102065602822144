<template>
<div class="childrenFill">
  <NavBar/>
  <div class="relativeDiv" style="overflow-y: hidden; display: flex; z-index: 3; overflow-x: hidden;">
    <PageHeader/>
    <PageContent :name="'Scenes'"/>
  </div>
</div>

</template>

<script setup>

import {
  onMounted, inject
} from 'vue';
import PageHeader from '../components/PageHeader.vue';
import PageContent from '../components/PageContent.vue';
import NavBar from '../components/NavBar.vue';
import { useAuth0 } from '@auth0/auth0-vue';
const { getAccessTokenSilently } = useAuth0();

const store = inject('store');

onMounted(() => {
  let token = getAccessTokenSilently();
  store.methods.apiCallAllProjects(token).then((message) => {
    if (message.data)
            {
            store.methods.updateProjects(message.data,token);
            }
    console.log('api message from scenes: ', store.state.allScenes);
  });
});

</script>

<style scoped>

</style>
