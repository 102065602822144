<template>
	<el-card class="world-card" :body-style="{ padding: '0px' }">
		<v-lazy-image v-if="props.world.image_path" class="image-container" :src="getThumbnail(props.world, false)"
			@click="openItem(props.world)" :src-placeholder="require('../assets/default.png')"
			style="object-fit: cover;" />
		<img v-else class="default-image" src="@/assets/default.png" alt="default-image" @click="openItem(props.world)">
		<dropdown v-if="store.state.isUserAdmin && props.world.o_owner || props.world.same_team">
			<li @click.stop="store.methods.toggleNewProjectVisibility(false, props.world, false)">
				Settings
			</li>
			<li @click.stop="store.methods.shareProjectModal(props.world)">
				Share
			</li>
		</dropdown>
		<div class=" world-card__footer">
			<div>
				<h3>{{ props.world.project_name }}</h3>
				<div class="world-statuses">
					<span v-if="props.world.publish_status === WORLD_STATUSES.PUBLISH"
						class="status published">Published</span>
					<span v-if="props.world.publish_status === WORLD_STATUSES.DRAFT"
						class="status drafted">Drafted</span>
				</div>
			</div>
			<div v-if="props.world.o_shared && !props.world.o_owner && !props.world.same_team" class="sharedby"><span
					class="sharedbytitle">Shared&nbsp;by</span><br />{{ props.world.o_teamname }}
			</div>
			<div v-if="props.world.o_shared && !props.world.o_owner && props.world.same_team" class="sharedby"><span
					class="sharedbytitle">Shared&nbsp;by</span><br />{{ props.world.owner_name }}
			</div>

		</div>
	</el-card>
</template>
<script setup>
/* eslint-disable */
import VLazyImage from "v-lazy-image";
import { WORLD_STATUSES } from '@/helpers/constants'
import Dropdown from './DropdownCtl.vue'
import {
	defineProps, inject
} from 'vue';

const props = defineProps({
	world: {
		type: Object,
		default: () => { }
	}
})

console.log(props.world);
const store = inject('store');

// const copyLink = (id) => {
//   navigator.clipboard.writeText(`${process.env.SEQUIN_VIEWERS_LINK}/world/${id}`)
//   ElMessage.success('Link copied to clipboard')
// }

const getThumbnail = (obj) => {
	var standardProjectUrl = store.state.assetEndpoint + obj.unique_project_id + '/' + obj.image_path;
	return standardProjectUrl;
}

const openItem = (item) => {
	store.methods.openScene(item);
};


</script>

<style lang="scss">
.world-card {
	position: relative;
	cursor: pointer;
	border-radius: 10px;
	min-width: 300px;

	.sharedby {
		font-size: 18px;
		font-weight: normal;
		font-family: 'GT-America-Regular';
		text-align: center;
	}

	.sharedbytitle {
		font-size: 18px;
		font-weight: normal;
		font-family: 'GT-America-Medium';
		color: #009DF8;
	}

	.el-card__body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}

	.image-container {
		width: 100%;
		height: 300px;
		background-size: cover;
		background-position: center center;
	}

	.default-image {
		height: 300px;
		background-color: #f6f6f6;
	}

	.dropdown {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	&__footer {
		padding: 20px 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		h3 {
			margin: 0;
			font-size: 18px;
			font-weight: normal;
			font-family: 'GT-America-Medium';
		}

		.share-button {
			width: 40px !important;
			height: 40px !important;

			img {
				width: 15px;
				height: 15px;
			}
		}

	}

	.statistics {
		margin-top: 5px;
		display: flex;
		justify-content: space-between;
		color: #d8d8d8;

		&--card {
			display: flex;
			align-items: center;
			gap: 5px;
		}
	}

	.world-statuses {
		.status {
			position: relative;
			font-size: 14px;
			padding-left: 20px;

			&:before {
				content: '';
				position: absolute;
				width: 12px;
				height: 12px;
				background-color: #A6A6A6;
				border-radius: 50%;
				left: 0;
				top: 2px;
			}
		}

		.published::before {
			background-color: #09B700;
		}

		.archived::before {
			background-color: #F00505;
		}

		.drafted::before {
			background-color: #A6A6A6;
		}
	}

	.online {
		background-color: var(--el-color-success);
	}

	.green {
		color: var(--el-color-success);
	}
}
</style>
