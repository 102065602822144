
const WORLD_STATUSES = {
	DRAFT: 'DRAFT',
	PUBLISH: 'PUBLISH'
}

const USER_ROLES = {
	SUPER_ADMIN: 'Super Admin',
	ADMIN: 'Admin',
	DASHBOARD_ADMIN: 'Dashboard admin',
	GUEST: 'Guest',
}

export { WORLD_STATUSES, USER_ROLES }
