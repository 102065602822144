import { ref, computed } from 'vue'
import axios from '@/axios/index'




export function useFileUpload () {
  let worldId = null
  let worldFile = null
  let accessToken=null;

  const MULTIPART_CHUNK_SIZE = 52428800 // 50Mb
  const promises = ref([])
  const etags = ref([])
  const uploadUrls = ref({})
  const chunks = ref([])
  const progressByChunk = ref([]) // array with progress for each chunk
  const progress = computed(() => {let percent = (progressByChunk.value.reduce((a, b) => a + b, 0) / progressByChunk.value.length).toFixed(2);
                                if (isNaN(percent))
                                {
                                  return "";
                                }
                                else
                                    return "Upload progress: " + (progressByChunk.value.reduce((a, b) => a + b, 0) / progressByChunk.value.length).toFixed(2) + "%"
                                }) // get average value from array
  const chunksLength = computed(() => chunks.value?.length)
  const uploadId = computed(() => uploadUrls.value?.uploadid)

  


  const createChunks = () => {
    let startPointer = 0
    const endPointer = worldFile.size
    while (startPointer < endPointer) {
      const newStartPointer = startPointer + MULTIPART_CHUNK_SIZE
      chunks.value.push(worldFile.slice(startPointer, newStartPointer, worldFile.type))
      startPointer = newStartPointer
    }
  }

  const getUploadUrl = async () => {
    const response = await axios.post(`/getprojectuploadurl`, {
        worldId: worldId,
        file: {
            fileName: worldFile.name,
            partsRequested: chunksLength.value,
            multiPart: true
        },
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    uploadUrls.value = response.data;
  }

  const uploadChunks = async () => {
    const axiosInstance = axios.create()
    delete axiosInstance.defaults.headers.common.Authorization
    progressByChunk.value = Array.from({ length: chunks.value.length }, () => 0)
    uploadUrls.value.urls.forEach((item, index) => {
      const promise = new Promise((resolve, reject) => {
        axiosInstance({
          method: 'put',
          url: item.url,
          data: chunks.value[index],
          headers: { 'Content-Type': worldFile.type },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            progressByChunk.value[index] = percentCompleted
        //    console.log(percentCompleted);
          }
        }).then((result) => {
          etags.value[item.PartNumber - 1] = {
            PartNumber: item.PartNumber,
            ETag: JSON.parse(result.headers.etag)
          }
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
      promises.value.push(promise)
    })
    await Promise.all(promises.value)
  }

  const completeMultipartUpload = async () => {
    let revID = uploadUrls.value.revisionId;
    const response = await axios.post(`/completemultipartupload`, {
      fileName: worldFile.name, etags: etags.value, uploadID: uploadId.value,worldId: worldId,revisionId:revID
    },
    {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
    return response.data
  }

  const uploadFile = async (id, file) => {
    worldId = id
    worldFile = file
    etags.value = []
    chunks.value = []
    createChunks()
    await getUploadUrl()
    await uploadChunks()
    return await completeMultipartUpload()
  }
  const setAccessToken = (token) => 
  {
    accessToken=token;
  }


  return {
    uploadFile, progress,setAccessToken  }
}
