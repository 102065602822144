<template>

<div class="childrenFill">
  <NavBar ref="navBar"/>
  <div class="relativeDiv" style=" display: flex; z-index: 3; overflow-x: hidden;">
    <PageHeader></PageHeader>
    <PageContent :name="'Projects'"></PageContent>
  </div> 
</div>

</template>

<script>

import {
  inject, onMounted,
} from 'vue';
import PageHeader from '../components/PageHeader.vue';
import PageContent from '../components/PageContent.vue';
import NavBar from '../components/NavBar.vue';
import { useAuth0 } from '@auth0/auth0-vue';



export default {
  name: 'TeamProjects',
  components: {
    NavBar,PageHeader, PageContent
  },
  setup() {
    const store = inject('store');
  
    onMounted(async () => {
      // store.methods.injectAuth().then((response) => {
      //   if (response === 'success') {
      //     // console.log('successful auth injection');
          const { getAccessTokenSilently } = useAuth0();

           store.methods.apiCallAllProjects(await getAccessTokenSilently()).then((message) => {
            if (message.data)
            {
              store.methods.updateProjects(message.data);
            }
             
             // console.log('api message from projects: ', store.state.allProjects);
           });
      //   }
      // });
    });

    return {
      store
    };
  },
};

</script>
